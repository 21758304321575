<template>
    <modal v-if="entry"
           variant="info"
           title="Modify Due Day"
           confirm-label="Done"
           @cancel="cancel"
           @confirm="entryMoveConfirm">
        <entry :entry="entry" hide-progress></entry>
        <p class="p-4 pb-0">
            <strong>Note:</strong> Income entries are ordered by due day, so select the new due day for this entry.
        </p>
        <div class="flex">
            <div class="flex-1">
                <!-- This item will grow or shrink as needed -->
            </div>
            <div class="flex-shrink-0 p-6">
                <f-datepicker :value="pickerDueDate"
                              inline
                              format="dd"
                              class="mx-auto"
                              :min-date="pickerMinDate"
                              :max-date="pickerMaxDate"
                              @input="pickerUpdateDueDay">
                </f-datepicker>
            </div>
            <div class="flex-1">
                <!-- This item will grow or shrink as needed -->
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/ui/modal/Modal";
import Entry from "@/views/dashboard/budget/Entry";
import moment from "moment/moment";
import {mapGetters, mapActions} from 'vuex'

export default {
    components: {
        Entry,
        Modal
    },
    props: {
        entry: {
            type: Object
        }
    },
    data() {
        return {
            originalDueDay: null
        }
    },
    watch: {
        entry() {
            this.originalDueDay = this.entry?.dueDay || null
        }
    },
    computed: {
        ...mapGetters(['budgetMonth']),
        pickerDueDate () {
            console.log('this.entry 2', this.entry)
            if (!this.entry.dueDay) {
                this.entry.dueDay = 1
            }
            return moment().date(this.entry.dueDay).toDate()
        },
        pickerMinDate () {
            return moment().date(this.entry.dueDay).startOf('month').toDate()
        },
        pickerMaxDate () {
            return moment().date(this.entry.dueDay).endOf('month').toDate()
        }
    },
    methods: {
        ...mapActions(['updateBudgetEntry', 'sortBudgetEntries']),
        pickerUpdateDueDay (value) {
            value = moment(value).format('D')
            this.entry.dueDay = value
        },
        cancel() {
            this.entry.dueDay = this.originalDueDay
            this.sortBudgetEntries()
            this.close()
        },
        async entryMoveConfirm () {
            try {
                await this.updateBudgetEntry(this.entry)
                this.close()
            } catch (error) {
                console.log('entryMoveConfirm error', error)
            }
        },
        close() {
            this.$emit('completed')
        }
    }
}
</script>
