<template>
    <div>
        <budget-right-header>
                <template v-slot:left>
                    <ub-button @click="back"
                               variant="secondary"
                               size="sm"
                               outline
                               icon="chevronLeft">
                        Back
                    </ub-button>
                </template>
                <template v-slot:right>
                    <ub-button @click="modify"
                               variant="secondary"
                               size="sm"
                               outline
                               icon="pencilAlt">
                        Edit
                    </ub-button>
                </template>
        </budget-right-header>
        <div class="bg-gray-100 border border-gray-300 rounded-md shadow-md p-1 grid grid-cols-4 gap-6">
            <div class="col-span-4 text-lg text-center font-bold">

                <div class="overflow-hidden bg-white shadow sm:rounded-lg border border-gray-300">
                    <div class="px-4 py-5 sm:px-6">
                        <h3 class="text-lg font-medium leading-6 text-gray-900">{{ debt.name }}</h3>
                        <p class="mt-1 max-w-2xl text-sm text-gray-500">{{ debt.currentBalance | currency }}</p>
                    </div>
                    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Credit Limit</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ debt.creditLimit | currency }}</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Interest Rate</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ debt.interestRate | percent }}</dd>
                            </div>
                            <div class="sm:col-span-1">
                                <dt class="text-sm font-medium text-gray-500">Payments Made</dt>
                                <dd class="mt-1 text-sm text-gray-900">{{ debt.totalPayments }}</dd>
                            </div>
<!--                            <div class="sm:col-span-1">-->
<!--                                <dt class="text-sm font-medium text-gray-500">Salary expectation</dt>-->
<!--                                <dd class="mt-1 text-sm text-gray-900">$120,000</dd>-->
<!--                            </div>-->
<!--                            <div class="sm:col-span-2">-->
<!--                                <dt class="text-sm font-medium text-gray-500">About</dt>-->
<!--                                <dd class="mt-1 text-sm text-gray-900">Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit deserunt qui eu.</dd>-->
<!--                            </div>-->
                            <div class="sm:col-span-2">
                                <debt-actions :debt="debt"/>
<!--                                <dt class="text-sm font-medium text-gray-500">History</dt>-->
<!--                                <dd class="mt-1 text-sm text-gray-900">-->
<!--                                    <ul role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">-->
<!--                                        <debt-action-entry v-for="action in debt.actions" :key="`debt-action-${action.id}`" :action="action"/>-->
<!--                                    </ul>-->
<!--                                </dd>-->
                            </div>
                        </dl>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import BudgetRightHeader from '@/views/dashboard/budget/BudgetRightHeader'
import DebtAmountTag from "@/views/dashboard/budget/DebtAmountTag";
import DebtActions from "@/views/dashboard/budget/DebtActions";

import {mapGetters, mapActions} from 'vuex'

export default {
    components: {
        BudgetRightHeader,
        DebtAmountTag,
        DebtActions
    },
    props: {
        debt: {
            type: Object
        }
    },
    computed: {
        icon() {
            if (this.debt.type === 'credit_card') {
                return 'creditCard'
            } else if (this.debt.type === 'loan') {
                return 'bankNote'
            }
            return 'unknown'
        }
    },
    methods: {
        ...mapActions(['setBudgetState', 'refreshDebts']),
        back() {
            this.setBudgetState({left: true, right: 'stats-debts'})
        },
        modify() {
            this.setBudgetState({
                left: true,
                right: 'modify-debt',
                activeDebt: true,
                data: this.debt
            })
        }
    }
}
</script>
