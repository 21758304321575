<template>
    <vue-loading :active="loading"
             :can-cancel="false"
             :opacity="opacity"
             translate="fade"
             blur="3px"
             :is-full-page="isFullPage"/>
</template>

<script>
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';
import {mapGetters} from "vuex";

export default {
    components: {
        VueLoading
    },
    props: {
        isFullPage: {
            type: Boolean,
            default: false
        },
        opacity: {
            type: Number,
            default: 0.5
        }
    },
    computed: {
        ...mapGetters(['loading'])
    }
}
</script>
