import {$helper} from "@/plugins/Helper";

const currencyFilter = function (value, includeSymbol = true) {
    if (!value) value = 0
    return $helper.currency(value, includeSymbol)
}

const percentFilter = function (value) {
    return value + '%'
}

const Filters = {
    install(Vue) {
        Vue.filter('currency', currencyFilter)
        Vue.filter('percent', percentFilter)
    }
}

export default Filters
