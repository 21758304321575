<template>
    <div>
        <group v-for="(group, index) in groups"
               :key="`group-${index}`"
               :group="group"
               @modify-group="modifyGroup"
               @modify-entry="modifyEntry">
        </group>
        <group v-if="hasUnassigned"
               :key="`unassigned-group`"
               :group="unassignedGroup"
               @modify-group="modifyGroup"
               @modify-entry="modifyEntry">
        </group>
    </div>
</template>

<script>
import Group from '@/views/dashboard/budget/Group'
import {mapGetters} from 'vuex'

export default {
    components: {
        Group
    },
    computed: {
        ...mapGetters(['groups', 'unassigned']),
        hasUnassigned() {
            return this.unassigned.group.length >= 1
        },
        unassignedGroup() {
            return {
                entries: this.unassigned.group
            }
        }
    },
    methods: {
        modifyGroup(group) {
            this.$emit('modify-group', group)
        },
        modifyEntry(entry) {
            this.$emit('modify-entry', entry)
        }
    }
}
</script>
