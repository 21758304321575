export default (error, exception) => {
    console.log('Generic500Exception', exception, error);
    return {
        reject: {
            exception,
            status: error.response.status,
            errors: error.response.data.error.data
        }
    };
} 
