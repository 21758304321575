<template>
    <div>
        <budget-right-header>
            <template v-slot:left>
                <ub-button v-if="group.id" @click="deleteGroup" outline variant="danger" icon="trash" class="float-left"
                           size="sm"></ub-button>
            </template>
            <template v-slot:right>
                <ub-button variant="secondary" @click="cancel" outline size="sm">Cancel</ub-button>
                <ub-button @click="save" size="sm">Save</ub-button>
            </template>
        </budget-right-header>
        <div class="bg-gray-100 border border-gray-300 rounded-md shadow-md p-4 grid grid-cols-2 gap-6">
            <div class="col-span-2 text-lg text-center font-bold">Group Details</div>
            <div class="col-span-2">
                <f-input label="Group Name"
                         placeholder="entry name"
                         v-model="group.name"
                         :error="hasError('name')"/>
            </div>
        </div>
        <modal v-if="showDelete" variant="danger" title="Are you sure?" confirm-label="Yes, Delete!"
               cancel-label="Oops! No" @confirm="deleteConfirmed" @cancel="deleteCanceled">
            Do you really want to delete this group? It can't be undone.
        </modal>
    </div>
</template>

<script>
import Modal from "@/components/ui/modal/Modal";
import BudgetRightHeader from '@/views/dashboard/budget/BudgetRightHeader'
import {mapGetters, mapActions} from 'vuex'

export default {
    components: {
        Modal,
        BudgetRightHeader
    },
    data() {
        return {
            errors: null,
            group: {
                id: null,
                name: ''
            },
            showDelete: false,
            originalGroup: null
        }
    },
    computed: {
        ...mapGetters([
            'budgetState',
            'budgetDate',
            'budgetMonth'
        ]),
        hasErrors() {
            return !!this.errors
        }
    },

    methods: {
        ...mapActions([
            'setBudgetState',
            'updateBudgetGroup',
            'addBudgetGroup',
            'deleteBudgetGroup'
        ]),
        hasError(key) {
            if (this.errors && key in this.errors) {
                return this.errors[key]
            }
            return null
        },
        async save() {
            if (!this.group.id) {
                this.errors = await this.addBudgetGroup({name: this.group.name})
                return;
            }
            this.errors = await this.updateBudgetGroup({id: this.group.id, name: this.group.name})
        },
        cancel() {
            this.$helper.resetImmutableObject(this.group, this.originalGroup)
            this.setBudgetState({left: true})
        },
        deleteGroup() {
            this.showDelete = true
        },
        deleteConfirmed() {
            this.deleteBudgetGroup({id: this.group.id})
            this.showDelete = false
        },
        deleteCanceled() {
            this.setBudgetState({left: 'groups'})
        }

    },
    mounted() {
        if (this.budgetState.data?.id) {
            this.group = this.budgetState.data
        }
        console.log('this.group', this.group)
        this.originalGroup = {...this.group}
    }

}
</script>
