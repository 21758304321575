import router from '@/router';

export default (error, exception) => {
    return {
        reject: {
            exception,
            status: error.response.status,
            errors: error.response.data.error.data
        }
    };
}
