<template>
    <transition-fade>
        <div>
            <!-- Currently this page spinner runs for too long so disabling it for now -->
            <!-- <spinner/> -->
            <router-view />
        </div>
    </transition-fade>
</template>

<script>
import Spinner from "@/components/ui/Spinner";
import TransitionFade from "@/components/transitions/TransitionFade";

export default {
    components: {
        Spinner,
        TransitionFade
    }
};
</script>
