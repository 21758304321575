<template>
    <!--    <div class="p-2 bg-gray-400 border-gray-800 rounded-md shadow-lg m-1 mb-6">-->
<!--    <div class="mb-6 rounded-md shadow-lg">-->
<!--        <div class="border border-gray-300 rounded-md">-->
<!--            <div class="flex rounded-md rounded-t-none">-->
<!--                <div :class="currentBalanceVariant" class="flex-1 text-sm text-center border border-gray-300 border-l-0 border-r border-t-0 border-b-0">-->
<!--                    <edit-in-place v-model="currentBalance" @updated="updateCurrentBalance" currency select-on-edit/>-->
<!--                    <div class="text-xs">Current Balance</div>-->
<!--                </div>-->
<!--                <div :class="outstandingVariant" class="flex-1 text-xs text-center border border-gray-300 border-l-0 border-r border-t-0 border-b-0">-->
<!--                    <div class="text-sm">{{ budgetToDateStats.outStanding | currency }}</div>-->
<!--                    <div class="text-xs">Out Standing</div>-->
<!--                </div>-->
<!--                <div :class="leftOverVariant" class="flex-1 text-xs text-center">-->
<!--                    <div class="text-sm">{{ budgetToDateStats.leftOver | currency }}</div>-->
<!--                    <div class="text-xs">Left Over</div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

    <div class="mb-6">
        <div class="relative">
            <div class="absolute inset-0 px-4 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center">
                <span class="bg-white px-2 text-sm text-gray-500">
                    <icon name="arrowUpTray" size="6"/>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import EditInPlace from "@/components/ui/form/EditInPlace";

export default {
    components: {
        EditInPlace
    },

    props: {
        income: {
            type: Object
        }
    },

    data() {
        return {
            currentBalance: 0
        }
    },

    computed: {
        ...mapGetters(['budget', 'incomes', 'budgetToDateStats']),

        year() {
            return this.$route.params.year
        },

        month() {
            return this.$route.params.month
        },

        budgetDate() {
            return this.year + '-' + this.month + '-01'
        },

        leftOver() {
            return (this.currentBalance - this.outStanding).toFixed(2)
        },

        currentBalanceVariant() {
            if (this.currentBalance < this.outStanding) {
                return 'bg-red-100'
            }

            if (this.currentBalance === this.outStanding) {
                return 'bg-gray-100'
            }

            return 'bg-green-100'
        },

        outstandingVariant() {
            if (this.outStanding === 0) {
                return 'bg-green-100'
            }

            if (this.outStanding === this.expenses) {
                return 'bg-gray-100'
            }

            return 'bg-yellow-100'
        },

        leftOverVariant() {
            if (this.leftOver === 0) {
                return 'bg-yellow-100'
            }

            if (this.leftOver < 0) {
                return 'bg-red-100'
            }

            return 'bg-green-100'
        }
    },

    methods: {
        ...mapActions(['updateBudget']),
        async updateCurrentBalance() {
            if (!isNaN(this.currentBalance)) {
                await this.updateBudget({currentBalance: this.currentBalance})
            }
        }
    },

    mounted() {
        this.currentBalance = this.budget.currentBalance
    }
}
</script>
