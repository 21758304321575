<template>
    <form @submit.prevent="prevent" autocomplete="off" name="lastpass-disable-search">
        <slot/>
    </form>
</template>

<script>
export default {
    props: {
        prevent: {
            type: Function,
            default: () => {}
        }
    }
}
</script>
