<template>
    <div>
        <list-container>
            <div class="sticky top-0">
                <list-header :active="isActive">
                    <list-collapse-button v-model="collapsed"/>
                    <list-title :pointer="isAssigned" @click="modifyIncome">
                        {{ incomeName || 'Unassigned' }}
                        <span v-if="isAssigned" class="text-xs text-gray-600">
                            <due-day :value="income.dueDay"/>
                        </span>
                    </list-title>
                    <template v-if="isAssigned">
                        <list-title-amount :pointer="true" :amount="income.amount" @click="modifyIncome"/>
                        <list-right-button icon="plus" @click="createEntry"/>
                    </template>
                </list-header>
                <list-sub-header :entries-count="this.income.entries.length" :collapsed="collapsed"/>
            </div>
            <draggable handle=".entry-handle" :list="income.entries" v-bind="dragOptions" @change="dragChanged">
                <entry v-for="(entry, index) in income.entries"
                       v-if="entryVisibility(entry)"
                       :key="`entry-${index}`"
                       :entry="entry"/>
            </draggable>
            <list-footer v-if="isAssigned">
                <list-stats-footer :income-id="income.id"/>
            </list-footer>
        </list-container>
        <due-day-update-modal :entry="changeDueDay" @completed="changeDueDay = null"/>
    </div>
</template>

<script>
    import IncomeHeader from '@/views/dashboard/budget/IncomeHeader'
    import DueDay from '@/components/ui/DueDay'
    import Draggable from 'vuedraggable'
    import DropZone from '@/components/ui/dragdrop/DropZone';
    import Entry from '@/views/dashboard/budget/Entry'
    import DueDayPicker from '@/views/dashboard/budget/DueDayPicker'
    import DueDayUpdateModal from "@/views/dashboard/budget/DueDayUpdateModal";
    import ListContainer from "@/components/layouts/list/ListContainer"
    import ListHeader from "@/components/layouts/list/ListHeader"
    import ListCollapseButton from "@/components/layouts/list/ListCollapseButton"
    import ListTitle from "@/components/layouts/list/ListTitle";
    import ListRightButton from "@/components/layouts/list/ListRightButton";
    import ListFooter from "@/components/layouts/list/ListFooter";
    import ListStatsFooter from "@/components/layouts/list/ListStatsFooter";
    import ListSubHeader from "@/components/layouts/list/ListSubHeader";
    import ListTitleAmount from "@/components/layouts/list/ListTitleAmount";
    import moment from "moment";
    import {mapGetters, mapActions} from 'vuex'

    export default {

        components: {
            IncomeHeader,
            Draggable,
            DropZone,
            Entry,
            DueDayPicker,
            DueDayUpdateModal,
            DueDay,
            ListContainer,
            ListHeader,
            ListTitle,
            ListCollapseButton,
            ListRightButton,
            ListFooter,
            ListStatsFooter,
            ListSubHeader,
            ListTitleAmount
        },

        props: {
            income: {
                type: Object
            }
        },

        data () {
            return {
                stats: {
                    expenses: 0,
                    outStanding: 0,
                    leftOver: 0
                },
                drag: false,
                dialog: null,
                collapsed: 2,
                dragging: null,
                changeDueDay: null,
                balances: null
            }
        },

        computed: {
            ...mapGetters([
                'budgetState',
                'budgetMonth',
                'hasActiveBudgetState'
            ]),
            incomeName() {
                return this.income?.name ? this.income.name : 'Unassigned'
            },
            isAssigned() {
                return !!this.income.id
            },
            isActive() {
                if (this.isAssigned) {
                    return this.budgetState.activeIncome === this.income.id
                }
                return false
            },
            dragOptions() {
                return {
                    animation: 200,
                    group: "entries",
                    disabled: false,
                    ghostClass: "ghost"
                };
            },
        },
        methods: {
            ...mapActions([
                'setBudgetState',
                'updateBudgetEntry',
                'sortBudgetEntries'
            ]),
            async initialCollapse () {
                let cleared = false
                for (let i = 0; i < this.income.entries.length; i++) {
                    if (this.income.entries[i].cleared === true) {
                        cleared++
                    }
                }

                if (cleared === this.income.entries.length) {
                    this.collapsed = 3
                } else if (cleared >= 1) {
                    this.collapsed = 2
                } else {
                    this.collapsed = 1
                }
            },
            createEntry () {
                this.setBudgetState({
                    right: 'modify-entry',
                    activeIncome: this.income.id,
                    data: {
                        id: null,
                        name: '',
                        autoPay: false,
                        dueDay: 1,
                        amount: 0.00,
                        budgetIncomeId: this.income.id,
                        budgetGroupId: null,
                        url: ''
                    }
                });
            },
            modifyIncome () {
                if (!this.isAssigned || this.hasActiveBudgetState) {
                    return;
                }

                this.setBudgetState({
                    right: 'modify-income',
                    activeIncome: this.income.id,
                    data: this.income
                });
            },
            async modifyEntry (entry) {
                try {
                    this.$emit('modify-entry', entry)
                } catch (error) {
                    console.log('error', error)
                }
            },
            dragChanged (evt) {
                if (evt.added) {
                    this.addedTo(evt.added.newIndex, evt.added.element)
                } else if (evt.moved) {
                    this.moveTo(evt.moved.oldIndex, evt.moved.newIndex, evt.moved.element)
                }
            },
            async addedTo (newIndex, entry) {
                try {
                    entry.budgetIncomeId = this.income.id
                    await this.updateBudgetEntry(entry)
                    this.collapsed = 1
                } catch (error) {
                    console.log('Income: addedTo error', error)
                }
            },
            async moveTo(oldIndex, newIndex, entry) {
                entry.oldIndex = oldIndex
                entry.newIndex = newIndex
                this.changeDueDay = entry
            },
            entryVisibility (entry) {
                if (this.collapsed === 1) {
                    return true
                } else if (this.collapsed === 2) {
                    return !entry.cleared
                }

                return false;
            }
        },
        async mounted () {
            await this.initialCollapse()
        }
    }
</script>

<style lang="scss" scoped>
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    visibility: hidden;
}
.list-group {
    min-height: 20px;
}
.list-group-item {
    cursor: move;
}
.list-group-item i {
    cursor: pointer;
}
</style>
