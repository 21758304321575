<template>
    <div>
        <label for="balanceInput" class="ml-2 block text-sm font-medium text-gray-500">Current Balance</label>
        <div class="mt-1 flex rounded-md shadow-sm">
            <div class="relative flex flex-grow items-stretch focus-within:z-10">
                <div class="pointer-events-none absolute text-2xl inset-y-0 left-0 flex items-center pl-3 text-gray-500">$</div>
                <input ref="balanceInput"
                       v-model="budget.currentBalance"
                       placeholder="0.00"
                       @focus="$event.target.select()"
                       v-on:keyup.enter="update"
                       class="block w-full bg-white text-2xl px-2 pl-7 pr-3 text-gray-500 border border-gray-300 rounded-none rounded-l-md border-gray-300"/>
            </div>
            <button type="button"
                    @click="update"
                    class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700">
                <icon name="save"></icon>
                <span>Update</span>
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    computed: {
        ...mapGetters(['budget'])
    },
    methods: {
        ...mapActions(['updateBudget']),
        async update(value) {
            await this.updateBudget({currentBalance: this.budget.currentBalance})
        }
    }
}
</script>
