export default {
    LOCAL_ACCESS_TOKEN_KEY: 'BUDGET_ACCESS_TOKEN',
    LOCAL_REFRESH_TOKEN_KEY: 'BUDGET_REFRESH_TOKEN',
    AUTH_USER_LOCKED: 'AUTH_USER_LOCKED',
    API_URL: env.VUE_APP_GRAPHQL_HTTP,
    API_API_URL: env.VUE_APP_API_HTTP,
    AVATAR_BASE_PATH: env.VUE_AVATAR_BASE_PATH,
    VIEW_GROUPS: 'groups',
    VIEW_INCOMES: 'incomes'
}
