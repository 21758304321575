<template>
    <div class="px-4">
        <div class="-mx-4 mt-4 p-1 flex flex-col bg-white rounded-lg shadow-lg border border-gray-300">
            <table class="min-w-full divide-y divide-gray-300">
                <thead>
                <th colspan="2" class="pb-1">
                    {{ title }}
                    <div class="text-xs text-gray-400 font-normal">{{ stats.payments }} payments made since tracking on app</div>
                </th>
                </thead>
                <tbody>
                <tr v-if="isCredit" class="border-b border-gray-200 bg-green-50">
                    <td class="py-1 pl-4 pr-3 text-sm">
                        <div class="font-medium text-gray-900">Total Credit</div>
                        <div class="mt-0.5 text-gray-500">The total credit limits</div>
                    </td>
                    <td class="py-4 pl-3 pr-4 text-right text-sm text-gray-500">{{ stats.creditLimit | currency }}</td>
                </tr>
                <tr class="border-b border-gray-200 bg-red-50">
                    <td class="py-1 pl-4 pr-3 text-sm">
                        <div class="font-medium text-gray-900">
                            <span v-if="isCredit">Credit Usage</span>
                            <span v-else>Balance</span>
                        </div>
                        <div class="mt-0.5 text-gray-500">
                            <span v-if="isCredit">The total debt used</span>
                            <span v-else>The total of the balances</span>
                        </div>
                    </td>
                    <td class="py-4 pl-3 pr-4 text-right text-sm text-gray-500">
                        <div>{{ stats.usage | currency }}</div>
                        <div v-if="isCredit">{{ stats.usagePercent | percent }}</div>
                    </td>
                </tr>
                <tr v-if="isCredit" class="border-b border-gray-200 bg-yellow-50">
                    <td class="py-1 pl-4 pr-3 text-sm">
                        <div class="font-medium text-gray-900">Available Credit</div>
                        <div class="mt-0.5 text-gray-500">The total amount available</div>
                    </td>
                    <td class="py-4 pl-3 pr-4 text-right text-sm text-gray-500">{{ stats.availableCredit | currency }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String
        },
        stats: {
            type: Object
        }
    },
    computed: {
        isCredit() {
            return !!this.stats.creditLimit
        }
    },
    mounted() {
        console.log('stats', this.stats)
    }
}
</script>
