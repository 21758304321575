<template>
    <div :class="wrapper">
        <slot/>
    </div>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        wrapper() {
            const classes = [
                'sticky pr-2',
                'top-0',
                'hidden',
                'md:flex',
                'border',
                'border-t-0',
                'border-l-0',
                'border-r-0',
                'border-b',
                'border-gray-300',
                'rounded-md',
                'rounded-b-none'
            ]
            if (this.active) {
                classes.push('bg-yellow-100', 'hover:bg-yellow-200')
            } else {
                classes.push('bg-gray-100', 'hover:bg-gray-200')
            }
            return classes
        }
    },
    //
    // methods: {
    //     click() {
    //         this.$emit('click')
    //     }
    // }
}
</script>
