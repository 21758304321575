<template>
    <transition name="fade" mode="out-in">
        <toast-notify-basic v-if="false" title="Success" text="This is a test"/>
    </transition>
</template>

<script>
import TransitionFade from '@/components/transitions/TransitionFade'
import ToastNotifyBasic from "@/components/layouts/toast/ToastNotifyBasic";
import {mapGetters, mapActions} from "vuex";

export default {
    components: {
        TransitionFade,
        ToastNotifyBasic
    },
    computed: {
        ...mapGetters(['toastNotify']),
        active() {
            return !!this.toastNotify
        }
    },
    watch: {
        toastNotify() {
            setTimeout(() => {
                this.setToastNotify(null)
            }, 2000)
        }
    },
    methods: {
        ...mapActions(['setToastNotify'])
    }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
    transition-delay: 2.00s;
}
</style>
