<template>
    <div :class="state" class="flex border-b rounded-md cursor-pointer m-1 pb-1">
        <div v-if="!hideHandle" class="flex-none border-r px-1 pt-4">
            <icon name="menu" fill size="4" class="entry-handle cursor-move text-gray-300 hover:text-gray-700"></icon>
        </div>
        <div @click="modify" class="flex-1 px-2 pt-1">
            {{ entry.name }}
            <span v-if="hideBadges" class="text-xs text-gray-500">
                {{dueDate}}
            </span>
            <div class="text-xs text-gray-600">
                <badge v-if="!hideBadges" xs>
                    Due: {{dueDate}}
                </badge>
                <badge v-if="!hideBadges && entry.autoPay" xs blue>
                    Auto Pay
                </badge>
                <badge v-if="!hideBadges && debt" xs green>
                    {{ debt.name }} {{ debt.interestRate | percent }}
                </badge>
            </div>
        </div>
        <div @click="modify" class="flex-none text-right px-2 pt-1">
            {{ entry.amount | currency }}
            <div class="text-xs text-gray-600">
                <badge v-if="!hideBadges && (entry.url && entry.url.length >= 1)" sx blue>
                    <a @click.stop="openWebsite" target="_blank">website</a>
                </badge>
            </div>
        </div>
        <div v-if="!hideProgress" class="flex-none rounded-md pt-2 pr-2">
            <entry-progress :progress="entry.status" @update="updateProgress"/>
        </div>
    </div>
</template>

<script>
import Currency from '@/components/ui/Currency'
import DueDay from '@/components/ui/DueDay'
import EntryProgress from '@/views/dashboard/budget/EntryProgress'
import EditInPlace from '@/components/ui/form/EditInPlace'
import Modal from '@/components/ui/modal/Modal'
import Badge from "@/components/ui/Badge";
import moment from 'moment'
import {mapActions, mapGetters} from 'vuex'

export default {
    components: {
        Currency,
        DueDay,
        EntryProgress,
        EditInPlace,
        Modal,
        Badge
    },
    props: {
        entry: {
            type: Object
        },
        hideProgress: {
            type: Boolean,
            default: false
        },
        separate: {
            type: Boolean,
            default: false
        },
        hideBadges: {
            type: Boolean,
            default: false
        },
        hideHandle: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            edit: null,
            dialog: null
        }
    },
    computed: {
        ...mapGetters(['budgetState', 'budgetMonth', 'hasActiveBudgetState', 'debts']),
        dueDate() {
            return moment(this.budgetMonth).set('date', this.entry.dueDay).format('Do')
        },
        debt() {
            if (!this.entry.debtId) {
                return null
            }
            return this.debts.find(d => d.id === this.entry.debtId)
        },
        statusColor() {
            if (this.entry.status === 'cleared') return 'green'
            if (this.entry.status === 'paid') return 'yellow'
            if (this.entry.status === 'goal') return 'red'
            return 'gray'
        },
        state() {
            const classes = [];
            if (this.budgetState.activeEntry === this.entry.id) classes.push('border bg-yellow-100 border-yellow-500')
            else if (this.entry.status === 'cleared') classes.push('bg-green-50 hover:bg-green-100')
            else if (this.entry.status === 'paid') classes.push('bg-yellow-50 hover:bg-yellow-100')
            else if (this.entry.status === 'goal') classes.push('bg-red-50 hover:bg-red-100')
            else classes.push('bg-white hover:bg-gray-100')

            if (!this.separate) {
                classes.push('rounded-b-none')
            }

            return classes;
        }
    },
    methods: {
        ...mapActions(['setBudgetState', 'updateBudgetEntry']),
        updateProgress(status) {
            this.updateBudgetEntry({
                id: this.entry.id,
                status
            });
        },
        modify() {
            if (this.hasActiveBudgetState) {
                return;
            }
            this.setBudgetState({
                left: true,
                right: 'modify-entry',
                activeEntry: this.entry.id,
                data: this.entry
            })
        },
        openWebsite() {
            window.open(this.entry.url, '_blank');
        }
    }
}
</script>
