<template>
    <div class="flex-none text-lg text-right px-3 py-2">
        <ub-button @click="increment"
                   size="sm" variant="secondary"
                   :icon="collapsedIcon"
                   outline>
        </ub-button>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            description: 'Set the value of the input. This is the other end of the v-model directive',
            type: [Number, String]
        }
    },
    computed: {
        collapsedIcon () {
            if (this.value === 1) {
                return 'chevronDoubleRight'
            } else if (this.value === 2) {
                return 'chevronDoubleDown'
            }

            return 'chevronDown'
        }
    },
    methods: {
        increment () {
            this.$emit('input', this.value < 3 ? this.value + 1 : 1)
        }
    }
}
</script>
