<template>
    <div class="flex rounded-md rounded-t-none border">
        <div v-if="isIncome" class="flex-1 px-3 text-sm text-center bg-red-100 border border-gray-300 border-l-0 border-r border-t-0 border-b-0">
            <div class="text-sm display-inline">{{ stats.expenses | currency }}</div>
            <div class="text-xs display-inline">Expense</div>
        </div>
        <div v-if="isGroup" class="flex-1 px-3 py-2 font-bold text-right bg-gray-100 border border-gray-300 border-l-0 border-r border-t-0 border-b-0">
            <div class="display-inline">{{ stats.expenses | currency }}</div>
        </div>
        <div v-if="isIncome" :class="outstandingVariant" class="flex-1 text-xs text-center border border-gray-300 border-l-0 border-r border-t-0 border-b-0">
            <div class="text-sm">{{ stats.outStanding | currency }}</div>
            <div class="text-xs">Out Standing</div>
        </div>
        <div v-if="isIncome" :class="leftOverVariant" class="flex-1 text-xs text-center">
            <div class="text-sm">{{ stats.leftOver | currency }}</div>
            <div class="text-xs">Left Over</div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        incomeId: {
            type: [Number, String],
        },
        groupId: {
            type: [Number, String],
        }
    },
    computed: {
        ...mapGetters(['incomeStats', 'groupStats']),
        isIncome() {
            return !!this.incomeId
        },
        isGroup() {
            return !!this.groupId
        },
        stats() {
            if (this.isIncome) {
                const stats = this.incomeStats[this.incomeId]
                if (stats) {
                    return stats
                }
            } else if (this.isGroup) {
                const stats = this.groupStats[this.groupId]
                if (stats) {
                    return stats
                }
            }
            return {
                expenses: 0,
                outStanding: 0,
                leftOver: 0
            }
        },
        outstandingVariant () {
            if (this.stats.outStanding === 0) {
                return 'bg-green-100'
            } else if (this.stats.outStanding === this.stats.expenses) {
                return 'bg-gray-100'
            }

            return 'bg-yellow-100'
        },

        leftOverVariant () {
            if (this.stats.leftOver === 0) {
                return 'bg-yellow-100'
            } else if (this.stats.leftOver < 0) {
                return 'bg-red-100'
            }

            return 'bg-green-100'
        }
    }
}
</script>
