<template>
    <div>
        <budget-right-header></budget-right-header>
        <div class="bg-gray-100 border border-gray-300 rounded-md shadow-md p-4">
            <div class="px-3 mb-3">
                <div class="text-center">
                    <h2 class="text-xl font-bold tracking-tight text-gray-900">Debt Stats</h2>
                    <p class="mt-2 text-sm text-gray-500">Details regarding your debt values and percentages</p>
                </div>
            </div>
            <debt-stats-type v-if="stats.debts.creditCard.usage > 0" title="Credit Card Stats" :stats="stats.debts.creditCard"/>
            <debt-stats-type v-if="stats.debts.loans.usage > 0" title="Loan Stats" :stats="stats.debts.loans"/>
            <debt-stats-type v-if="stats.debts.mortgage.usage > 0" title="Mortgage Stats" :stats="stats.debts.mortgage"/>
            <debt-stats-type v-if="stats.debts.other.usage > 0" title="Other Stats" :stats="stats.debts.other"/>












<!--            <div class="px-4">-->
<!--                <div class="-mx-4 mt-4 p-1 flex flex-col bg-white rounded-lg shadow-lg border border-gray-300">-->
<!--                    <table class="min-w-full divide-y divide-gray-300">-->
<!--                        <thead>-->
<!--                        <th colspan="2" class="pb-1">-->
<!--                            Credit Card Stats-->
<!--                            <div class="text-xs text-gray-400 font-normal">{{ creditCards.payments }} payments made since tracking on app</div>-->
<!--                        </th>-->
<!--                        </thead>-->
<!--                        <tbody>-->
<!--                        <tr class="border-b border-gray-200 bg-green-50">-->
<!--                            <td class="py-1 pl-4 pr-3 text-sm">-->
<!--                                <div class="font-medium text-gray-900">Total Credit</div>-->
<!--                                <div class="mt-0.5 text-gray-500">The total credit limits</div>-->
<!--                            </td>-->
<!--                            <td class="py-4 pl-3 pr-4 text-right text-sm text-gray-500">{{ creditCards.creditLimit | currency }}</td>-->
<!--                        </tr>-->
<!--                        <tr class="border-b border-gray-200 bg-red-50">-->
<!--                            <td class="py-1 pl-4 pr-3 text-sm">-->
<!--                                <div class="font-medium text-gray-900">Credit Usage</div>-->
<!--                                <div class="mt-0.5 text-gray-500">The total debt used</div>-->
<!--                            </td>-->
<!--                            <td class="py-4 pl-3 pr-4 text-right text-sm text-gray-500">-->
<!--                                <div>{{ creditCards.usage | currency }}</div>-->
<!--                                <div>{{ creditCards.usagePercent | percent }}</div>-->
<!--                            </td>-->
<!--                        </tr>-->
<!--                        <tr class="border-b border-gray-200 bg-yellow-50">-->
<!--                            <td class="py-1 pl-4 pr-3 text-sm">-->
<!--                                <div class="font-medium text-gray-900">Available Credit</div>-->
<!--                                <div class="mt-0.5 text-gray-500">The total amount available</div>-->
<!--                            </td>-->
<!--                            <td class="py-4 pl-3 pr-4 text-right text-sm text-gray-500">{{ creditCards.availableCredit | currency }}</td>-->
<!--                        </tr>-->
<!--                        </tbody>-->
<!--                    </table>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
import BudgetRightHeader from "@/views/dashboard/budget/BudgetRightHeader";
import DebtStatsType from "@/views/dashboard/budget/DebtStatsType";

import { mapGetters } from 'vuex'

export default {
    components: {
        BudgetRightHeader,
        DebtStatsType
    },
    computed: {
        ...mapGetters(['stats'])
    }
}
</script>
