<template>
    <f-input 
        :id="id"
        v-model="formattedValue"
        :label="label"
        right
        :placeholder="placeholder"
        left-add-on="$"
        :error="error"
        :position="position"
        @keyup="keypressUpdate"
        @blur="blurUpdate"
    />
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            default: 'currencyInput'
        },
        label: {
            description: 'The group label',
            type: String,
            default: null
        },
        value: {
            description: 'Set the value of the input. This is the other end of the v-model directive',
            type: [String, Number]
        },
        placeholder: {
            description: 'The field placeholder',
            type: String
        },
        prefix: {
            type: String
        },
        error: {
            description: 'The error to display if one exists',
            type: String
        }
    },
    data() {
        return {
            formattedValue: '',
            position: 0
        }
    },
    methods: {
        format(number) {
            if (typeof number !== 'string') number = number.toString();
            if (!number) number = '';
            number = number.replaceAll(/\D/g, '');

            if (number.length === 0) number = '000';
            else if (number.length === 1) number = `00${number}`;
            else if (number.length === 2) number = `0${number}`;

            console.log("number", number);

            const pos = number.length - 2;
            if (pos < 0) pos = 0;

            let dollars = number.slice(0, pos).replace(/^0+/, '');
            // if (dollars.length === 0) dollars = '0';

            if (this.prefix) {
                dollars = `${this.prefix}${dollars}`;
            }

            const cents = number.slice(pos);

            this.formattedValue = `${dollars}.${cents}`;
            // this.position = this.formattedValue.length;
        },
        cleanNumber() {
            this.formattedValue = this.formattedValue.replaceAll(/[^0-9.]/g, '');
        },
        keypressUpdate(value, event) {
            this.format(value);
            this.cleanNumber();
            this.$emit('input', this.formattedValue);
        },
        blurUpdate() {
            this.cleanNumber();
            console.log('this.formattedValue', this.formattedValue);
            this.$emit('input', this.formattedValue);
        }
    },
    created() {
        console.log('currency value', `'${this.value}'`);
        this.format(this.value);
        console.log('currency formattedValue', this.formattedValue);
    }
}
</script>
