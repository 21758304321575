<template>
    <div :class="{'cursor-pointer': pointer}"
         class="flex-none flex-1 text-xl pb-2 pt-3 pr-2"
         @click="click">
        {{ amount | currency }}
    </div>
</template>

<script>
export default {
    props: {
        pointer: {
            type: Boolean,
            default: false
        },
        amount: {
            type: [String, Number],
            default: 0
        }
    },
    methods: {
        wrapper() {

        },
        click() {
            this.$emit('click')
        }
    }
}
</script>
