<template>
    <button type="button" @click="setChecked" class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" role="switch" aria-checked="false">
        <span class="sr-only">Use setting</span>
        <span aria-hidden="true" class="pointer-events-none absolute h-full w-full rounded-md bg-white"></span>
        <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
        <span aria-hidden="true" :class="{'bg-indigo-600': isChecked, 'bg-gray-200': !isChecked}" class="pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"></span>
        <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
        <span aria-hidden="true" :class="{'translate-x-5': isChecked, 'translate-x-0': !isChecked}" class="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"></span>
    </button>
</template>

<script>
export default {
    props: {
        value: {
            type: [Boolean, Number, String]
        }
    },
    data() {
        return {
            // isChecked: false
        }
    },
    computed: {
        isChecked() {
            return !!this.value
        }
    },
    methods: {
        setChecked() {
            this.$emit('input', !this.value)
        }
    }
}
</script>
