import { request } from '@/scripts/http/utils';

/**
 * Budget Debt Requests
 */

export default {
    // Get all debts for the given budget by month
    getDebts: (relations) => {
        return request('get', ['debts'], {}, relations)
    },

    // Get a budget debt
    getDebt: (debtId, relations) => {
        return request('get', ['debts', debtId], {}, relations)
    },

    // Create a budget debt
    addDebt: (debt, relations) => {
        return request('post', ['debts'], debt, relations)
    },

    // Update a budget debt
    updateDebt: (debtId, debt, relations) => {
        return request('patch', ['debts', debtId], debt, relations)
    },

    // Delete a budget debt
    deleteDebt: (debtId, relations) => {
        return request('delete', ['debts', debtId], {}, relations)
    }
}
