<template>
    <div>
        <div>
            <div class="flex-none text-lg text-right cursor-pointer px-3 py-3">
                Testing
            </div>
        </div>
    </div>
</template>

<script>

    export default {

    }

</script>
