<template>
    <div class="relative">
        <div class="absolute inset-0 flex items-center ml-2 mr-2" aria-hidden="true">
            <div class="w-full border-t border-gray-300"></div>
        </div>
        <div v-if="label" class="relative flex justify-center">
            <span class="bg-white px-2 text-sm text-gray-500">{{ label }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String
        }
    }
}
</script>
