<template>
    <div>
        <f-form :prevent="save">
            <budget-right-header>
                <template v-slot:left>
                    <ub-button v-if="editMode"
                               @click="showDelete = true"
                               outline variant="danger" size="sm"
                               icon="trash"
                               class="float-left"/>
                </template>
                <template v-slot:right>
                    <ub-button variant="secondary" @click="cancel" size="sm" outline>Cancel</ub-button>
                    <ub-button type="submit" size="sm">Save</ub-button>
                </template>
            </budget-right-header>

            <div class="bg-gray-100 border border-gray-300 rounded-md shadow-md p-4 grid grid-cols-4 gap-6">
                <div class="col-span-2 text-lg text-center font-bold">
                    <template v-if="editMode">Edit Debt</template>
                    <template v-else>Create Debt</template>
                </div>
                <div class="col-span-4">
                    <f-input label="Debt Name" v-model="debt.name" :error="hasError('name')">
                        <template v-slot:right-add-on>
                            <div class="absolute inset-y-0 right-0 flex items-center">
                                <select v-model="debt.type" class="h-full rounded-md border-transparent bg-transparent text-right py-0 pl-2 pr-2 text-gray-500 focus:border-0 sm:text-sm">
                                    <option v-for="type in debtTypes" :key="`debt-type-${type.key}`" :value="type.key">{{type.name}}</option>
                                </select>
                            </div>
                        </template>
                    </f-input>
                </div>
                <div class="col-span-2">
                    <f-input label="Credit Limit"
                             placeholder="0.00"
                             v-model="debt.creditLimit"
                             left-add-on="$"
                             :error="hasError('creditLimit')"/>
                </div>
                <div class="col-span-2">
                    <f-input label="Current Balance"
                             placeholder="0.00"
                             v-model="debt.currentBalance"
                             left-add-on="$"
                             :error="hasError('currentBalance')"/>
                </div>
                <div class="col-span-2">
                    <f-input label="Rate"
                             right
                             placeholder="0.0"
                             v-model="debt.interestRate"
                             right-add-on="%"
                             :error="hasError('interestRate')"/>
                </div>
            </div>
        </f-form>
    </div>
</template>

<script>
import Modal from "@/components/ui/modal/Modal";
import BudgetRightHeader from '@/views/dashboard/budget/BudgetRightHeader'
import {mapGetters, mapActions} from 'vuex'

export default {
    components: {
        Modal,
        BudgetRightHeader,
    },

    props: {
        debt: {
            type: Object
        }
    },

    data() {
        return {
            errors: null,
            showDelete: false,
            originalDebt: null
        }
    },

    computed: {
        ...mapGetters(['debtTypes']),
        hasErrors() {
            return !!this.errors
        },
        editMode() {
            return !!this.debt?.id
        }
    },

    methods: {
        ...mapActions(['setBudgetState', 'addDebt', 'updateDebt', 'deleteDebt']),
        hasError(key) {
            if (this.errors && key in this.errors) {
                console.log('this.errors[key]', this.errors[key])
                return this.errors[key]
            }
            return null
        },

        cancel() {
            this.$helper.resetImmutableObject(this.debt, this.originalDebt)
            this.setBudgetState({
                left: true,
                right: this.editMode ? 'details-debt' : null,
                activeDebt: true,
                data: true
            })
        },

        async save() {
            this.errors = null
            if (this.editMode) {
                this.errors = await this.updateDebt(this.debt)
                console.log('save')
            } else {
                this.errors = await this.addDebt(this.debt)
            }
            if (!this.hasErrors) {
                this.setBudgetState({
                    left: true,
                    right: 'details-debt',
                    activeDebt: true,
                    data: true
                })
            }
        },

        setDefaultDebt() {
            if (!this.editMode) {
                this.debt.name = ''
                this.debt.active = true
                this.debt.type = 'credit_card'
                this.debt.creditLimit = 0.00
                this.debt.currentBalance = 0.00
                this.debt.interestRate = 0.0
            }
            this.originalDebt = {...this.debt}
        }
    },

    created() {
        this.setDefaultDebt()
    }
}

</script>
