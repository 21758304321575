<template>
    <layout>
        <toast-notify/>
        <inactive-alert></inactive-alert>
        <router-view></router-view>
    </layout>
</template>

<script>
import Layout from '@/components/layouts/Layout'
import InactiveAlert from '@/components/layouts/InactiveAlert'
import ToastNotify from "@/components/layouts/toast/ToastNotify";

export default {
    components: {
        Layout,
        ToastNotify,
        InactiveAlert
    }
}
</script>
