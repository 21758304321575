<template>
    <div>
        <budget-right-header></budget-right-header>
        <div
            class="bg-gray-100 border border-gray-300 rounded-md shadow-md p-4"
        >
            <div class="px-3 mb-3">
                <div class="text-center">
                    <h2 class="text-xl font-bold tracking-tight text-gray-900">
                        Monthly Stats
                    </h2>
                    <p class="mt-2 text-sm text-gray-500">
                        Details regarding your standing for this month's budget
                    </p>
                </div>
            </div>

            <div class="relative mt-4 mb-3">
                <div
                    class="absolute inset-0 flex items-center"
                    aria-hidden="true"
                >
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-center">
                    <span class="bg-gray-100 px-2 text-lg text-gray-500"
                        >Current Standing</span
                    >
                </div>
            </div>

            <div class="mt-4">
                <budget-balance-input
                    v-model="budget.currentBalance"
                    class="shadow-lg"
                />
            </div>

            <div class="px-4">
                <div
                    v-if="!!stats.budget.income"
                    class="-mx-4 mt-8 flex flex-col bg-white py-2 rounded-lg shadow-lg border border-gray-300"
                >
                    <table class="min-w-full divide-y divide-gray-300">
                        <tbody>
                            <tr class="border-b border-gray-200">
                                <td class="py-1 pl-4 pr-3 text-sm">
                                    <div class="font-medium text-gray-900">
                                        Income Received
                                    </div>
                                    <div class="mt-0.5 text-gray-500">
                                        Income so far received
                                    </div>
                                </td>
                                <!--                            <td class="hidden py-4 px-3 text-right text-sm text-gray-500">12.0</td>-->
                                <td
                                    class="py-4 px-3 text-right text-sm text-gray-500"
                                ></td>
                                <td
                                    class="py-4 pl-3 pr-4 text-right text-sm text-gray-500"
                                >
                                    {{
                                        stats.budget.income.received | currency
                                    }}
                                </td>
                            </tr>

                            <tr class="border-b border-gray-200">
                                <td class="py-1 pl-4 pr-3 text-sm">
                                    <div class="font-medium text-gray-900">
                                        Balance
                                    </div>
                                    <div class="mt-0.5 text-gray-500">
                                        Current Bank Account Balance
                                    </div>
                                </td>
                                <!--                            <td class="hidden py-4 px-3 text-right text-sm text-gray-500">12.0</td>-->
                                <td
                                    class="py-4 px-3 text-right text-sm text-gray-500"
                                ></td>
                                <td
                                    class="py-4 pl-3 pr-4 text-right text-sm text-gray-500"
                                >
                                    {{ budget.currentBalance | currency }}
                                </td>
                            </tr>

                            <tr class="border-b border-gray-200">
                                <td class="py-1 pl-4 pr-3 text-sm">
                                    <div class="font-medium text-gray-900">
                                        Outstanding
                                    </div>
                                    <div class="mt-0.5 text-gray-500">
                                        Amount Still Needs Paid
                                    </div>
                                </td>
                                <!--                            <td class="hidden py-4 px-3 text-right text-sm text-gray-500">12.0</td>-->
                                <td
                                    class="py-4 px-3 text-right text-sm text-gray-500"
                                >
                                    {{
                                        stats.budget.outStanding.percent
                                            | percent
                                    }}
                                </td>
                                <td
                                    class="py-4 pl-3 pr-4 text-right text-sm text-gray-500"
                                >
                                    {{
                                        stats.budget.outStanding.received
                                            | currency
                                    }}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th
                                    scope="row"
                                    colspan="2"
                                    class="pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900"
                                >
                                    Safe To Spend
                                </th>
                                <td
                                    class="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900"
                                >
                                    {{ stats.budget.safeToSpend | currency }}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

            <div class="relative mt-4 mb-4">
                <div
                    class="absolute inset-0 flex items-center"
                    aria-hidden="true"
                >
                    <div class="w-full border-t border-gray-300"></div>
                </div>
                <div class="relative flex justify-center">
                    <span class="bg-gray-100 px-2 text-lg text-gray-500"
                        >Need to Pay</span
                    >
                </div>
            </div>

            <div>
                <entry
                    v-for="entry in entries"
                    :key="`entry-${entry.id}`"
                    class="border border-gray-300 shadow-lg mb-3"
                    :entry="entry"
                    hide-progress
                    separate
                    hide-badges
                    hide-handle
                />
            </div>
        </div>
    </div>
</template>

<script>
import BudgetRightHeader from "@/views/dashboard/budget/BudgetRightHeader";
import BudgetStatsSection from "@/views/dashboard/budget/BudgetStatsSection";
import BudgetStatsDivider from "@/views/dashboard/budget/BudgetStatsDivider";
import BudgetBalanceInput from "@/views/dashboard/budget/BudgetBalanceInput";
import Entry from "@/views/dashboard/budget/Entry";
import moment from "moment/moment";
import { mapGetters } from "vuex";

export default {
    components: {
        BudgetRightHeader,
        BudgetStatsSection,
        BudgetStatsDivider,
        BudgetBalanceInput,
        Entry
    },
    computed: {
        ...mapGetters(["budgetMonth", "budget", "incomes", "stats"]),
        entries() {
            const entries = [];
            this.incomes.forEach(income => {
                if (this.showToDateStats(income)) {
                    income.entries.forEach(entry => {
                        if (!entry.paid && !entry.cleared) {
                            entries.push(entry);
                        }
                    });
                }
            });
            return entries;
        }
    },
    methods: {
        showToDateStats(income) {
            if (moment().format("YYYY-MM-01") === this.budgetMonth) {
                return income.dueDay <= moment().date();
            }
            return false;
        }
    }
};
</script>
