<template>
    <div>
        <dt class="text-sm font-medium text-gray-500">Balance History</dt>
        <dd class="mt-1 text-sm text-gray-900">
            <ul role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">
                <template v-if="balances.length >= 1">
                    <li v-for="balance in balances" class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                        <div class="flex w-0 flex-1 items-center">
                            {{ actionDate(balance) }}
                        </div>
                        <div class="ml-4 flex-shrink-0">
                            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
                                {{ balance.amount | currency }}
                            </a>
                        </div>
                    </li>
                </template>
            </ul>
        </dd>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    props: {
        debt: {
            type: Object
        }
    },
    computed: {
        balances() {
            console.log('balances', this.debt.actions.filter(action => action.actionType === 'balance'));
            return this.debt.actions.filter(action => action.actionType === 'balance');
        }
    },
    methods: {
        actionDate(balance) {
            return moment(balance.actionDate).format('MM/DD/YYYY')
        }
    }
}
</script>
