<template>
    <div :class="wrapper">
        <div v-if="entriesCount === 0" class="text-gray-400 text-gray-50 text-sm px-4 py-1">
            no entries
        </div>
        <div v-else-if="collapsed === 3" class="bg-gray-50 text-gray-400 text-sm px-4 py-1">
            all hidden
        </div>
        <div v-else-if="collapsed === 2" class="flex bg-gray-50 text-gray-400 text-sm px-4 py-1">
            cleared hidden
        </div>
    </div>
</template>

<script>
export default {
    props: {
        entriesCount: {
            type: Number,
            default: 0
        },
        collapsed: {
            type: Number,
            default: 2
        }
    },
    computed: {
        wrapper() {
            if (this.entriesCount === 1) {
                return 'border-b border-gray-200'
            }
        }
    }
}
</script>
