import store from '@/store'
import moment from "moment";

/**
 * This middleware sets the budgetMonth in the store
 *   if the url params year and month exist
 *
 * @param to The to route
 * @param from The from route
 * @param next The callback to move on
 */
export default async (to, from, next) => {
    if (to.name === 'budget') {
        if (to.params.year && to.params.month) {
            await store.dispatch('setBudgetMonth', `${to.params.year}-${to.params.month}-01`)
        } else {
            const year = moment().format('YYYY')
            const month = moment().format('MM')
            await store.dispatch('setBudgetMonth', null)
            return next({name: 'budget', params: {year, month}})
        }
    }
    next()
}
