<template>
    <div class="flex-none text-lg text-right pr-1 py-2">
        <ub-button @click="click" size="sm" variant="secondary" :icon="icon" outline></ub-button>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: ''
        }
    },
    methods: {
        click() {
            this.$emit('click')
        }
    }
}
</script>
