<template>
    <div>
        <f-input label="Due Day" :disabled="checked" class="p-0">
            <f-datepicker :hidden="checked"
                          :value="pickerDueDate"
                          :inline="inline"
                          format="dsu"
                          :min-date="pickerMinDate"
                          :max-date="pickerMaxDate"
                          @input="pickerUpdateDueDay">
            </f-datepicker>
            <div v-if="checked">
                End of Month
            </div>
            <template v-if="showCheckbox" v-slot:right-add-on>
                <div class="flex items-center">
                    <f-switch :value="checked" @input="setLastDay"/>
                </div>
            </template>
        </f-input>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        props: {
            value: {
                type: [String, Number]
            },
            date: {
                type: String
            },
            inline: {
                type: Boolean,
                default: false
            },
            showCheckbox: {
                type: Boolean,
                default: false
            },
            checked: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            pickerDueDate() {
                return moment(this.date).date(this.value).toDate()
            },
            pickerMinDate () {
                return moment(this.date).startOf('month').toDate()
            },
            pickerMaxDate () {
                return moment(this.date).endOf('month').toDate()
            }
        },
        methods: {
            pickerUpdateDueDay (value, end = null) {
                this.lastDay = false
                value = moment(value).format('D')
                this.$emit('input', value)
            },
            setLastDay(value) {
                this.$emit('checked', value)
            }
        },
        mounted() {
            console.log('this.checked', this.checked)
        }
    }

</script>
