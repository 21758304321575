import Vuex from "vuex";
import Vue from "vue";
import config from "@/config";
import moment from "moment";
import { $helper } from "@/plugins/Helper";
import { $http } from "@/scripts/http";
import router from "@/router";

// Add Vuex to the Vue instance
Vue.use(Vuex);

const fullBudgetRelations = [
    "incomes",
    "incomes.entries",
    "unassignedIncomeEntries",
    "groups",
    "groups.entries",
    "unassignedGroupEntries"
];

const sortEntries = data => {
    if (Array.isArray(data)) {
        data.sort(function(a, b) {
            if (a.dueDay === b.dueDay) {
                return a.name.localeCompare(b.name);
            }
            return a.dueDay - b.dueDay;
        });
    } else {
        console.log("sortEntries: data is not a sortable array", data);
    }
};

// Define the store
export default new Vuex.Store({
    state: {
        auth: {
            appLoaded: false,
            isLoggedIn: false,
            isLocked: false,
            tokens: {
                config: {
                    // The initial interval for checking ttl
                    // Once countdown is started it will be 1
                    timeout: 5,
                    // The ttl value where the countdown will start
                    notice: 60
                },
                // The access jwt token details
                access: {
                    ttl: 0,
                    token: ""
                },
                // The refresh jwt token details
                refresh: {
                    ttl: 0,
                    token: ""
                }
            }
        },
        toastNotify: null,
        loading: false,
        site: null,
        budgetDate: null,
        budgetMonth: null,
        budget: null,
        incomes: [],
        groups: [],
        unassigned: {
            income: [],
            group: []
        },
        debtTypes: [
            { key: "credit_card", name: "Credit Card" },
            { key: "loan", name: "Loan" },
            { key: "mortgage", name: "Mortgage Related" }
        ],
        debts: [],
        stats: {
            budget: {},
            debts: {}
        },
        incomeStats: [],
        groupStats: [],
        budgetToDateStats: {
            id: null,
            outStanding: 0,
            leftOver: 0
        },
        user: {},
        settings: {},
        budgetState: {
            left: "incomes",
            right: null,
            activeEntry: null,
            activeIncome: null,
            activeGroup: null,
            activeDebt: null,
            data: {}
        }
    },
    getters: {
        toastNotify: state => state.toastNotify,
        accessToken: state => state.auth.tokens.access || { ttl: 0, token: "" },
        appLoaded: state => state.auth.appLoaded,
        isLoading: state => state.loading,
        isLoggedIn: state => state.auth.isLoggedIn,
        isLocked: state => {
            const user = localStorage.getItem(config.AUTH_USER_LOCKED);
            state.auth.isLocked = JSON.parse(user);
            return state.auth.isLocked;
        },
        refreshToken: state => {
            const token = localStorage.getItem(config.LOCAL_REFRESH_TOKEN_KEY);
            state.auth.tokens.refresh.token = token;
            return token;
        },
        tokensConfig: state => state.auth.tokens.config,
        user: state => state.user,
        fullName: state => state.user.firstName + " " + state.user.lastName,
        email: state => state.user.email,
        avatar: state => {
            const avatar = state.user.avatar;
            if (avatar && avatar.length > 0) {
                return `${config.AVATAR_BASE_PATH}/${avatar}`;
            }
            // default if since an avatar is not set
            // return '/assets/static/img/no-photo.png'
        },
        site: state => state.site,
        settings: state => state.settings,
        budget: state => state.budget,
        incomes: state => state.incomes || [],
        groups: state => state.groups || [],
        unassigned: state => state.unassigned,
        budgetMonth: state => state.budgetMonth,
        budgetDate: state => {
            if (state.budget) {
                return moment(state.budget.month, "YYYY-M-DD");
            } else {
                return moment();
            }
        },
        budgetDateFormatted: state => {
            if (state.budget) {
                return moment(state.budget.month, "YYYY-M-DD").format(
                    "YYYY-MM-DD"
                );
            } else {
                return moment().format("YYYY-MM-DD");
            }
        },
        debtTypes: state => state.debtTypes,
        debts: state => state.debts,
        stats: state => state.stats,
        incomeStats: state => state.incomeStats,
        groupStats: state => state.groupStats,
        budgetToDateStats: state => state.budgetToDateStats,
        budgetState: state => state.budgetState,
        hasActiveBudgetState: state =>
            !!state.activeIncome ||
            !!state.activeEntry ||
            !!state.activeGroup ||
            !!state.activeDebt
    },
    mutations: {
        loading(state, loading) {
            state.loading = loading;
        },
        toastNotify(state, toastNotify) {
            state.toastNotify = toastNotify;
        },
        appLoaded(state, loaded) {
            state.auth.appLoaded = loaded;
        },
        loggedIn(state, loggedIn) {
            state.auth.isLoggedIn = loggedIn;
        },
        locked(state, user) {
            if (user) {
                localStorage.setItem(
                    config.AUTH_USER_LOCKED,
                    JSON.stringify(user)
                );
            } else {
                localStorage.removeItem(config.AUTH_USER_LOCKED);
            }
        },
        user(state, user) {
            state.user = user;
        },
        site(state, site) {
            state.site = site;
        },
        avatar(state, avatar) {
            state.user.avatar = avatar;
        },
        async settings(state, settings) {
            state.settings = settings;
        },
        tokens(state, tokens) {
            if (!tokens) {
                state.auth.tokens.access.token = "";
                state.auth.tokens.access.ttl = 0;
                state.auth.tokens.refresh.token = "";
                state.auth.tokens.refresh.ttl = 0;
                localStorage.removeItem(config.LOCAL_REFRESH_TOKEN_KEY);
            } else {
                state.auth.tokens.access = tokens.access;
                state.auth.tokens.refresh = tokens.refresh;
                localStorage.setItem(
                    config.LOCAL_REFRESH_TOKEN_KEY,
                    tokens.refresh.token
                );
            }
        },
        budgetDate(state, date) {
            state.budgetDate = date;
        },
        budgetMonth(state, date) {
            state.budgetMonth = date;
        },
        budget(state, budget) {
            state.budget = budget;
        },
        incomes(state, incomes) {
            state.incomes = incomes;
        },
        groups(state, groups) {
            state.groups = groups;
        },
        unassigned(state, unassigned) {
            if (!unassigned) unassigned = {};
            state.unassigned.income = Array.isArray(unassigned?.income)
                ? unassigned.income
                : [];
            state.unassigned.group = Array.isArray(unassigned?.group)
                ? unassigned.group
                : [];
        },
        debts(state, debts) {
            state.debts = debts || [];
        },
        stats(state, stats) {
            if (!stats) stats = {};
            state.stats.budget = stats?.budget || {};
            state.stats.debts = stats?.debts || {};
        },
        incomeStats(state, incomeStats) {
            state.incomeStats = incomeStats;
        },
        groupStats(state, groupStats) {
            state.groupStats = groupStats;
        },
        budgetToDateStats(state, stats) {
            state.budgetToDateStats = {
                id: stats.id,
                outStanding: stats.outStanding,
                leftOver: stats.leftOver
            };
        },
        budgetState(state, budgetState) {
            if (!budgetState) budgetState = {};
            state.budgetState = {
                left: budgetState?.left || null,
                right: budgetState?.right || null,
                activeEntry: budgetState?.activeEntry || null,
                activeIncome: budgetState?.activeIncome || null,
                activeGroup: budgetState?.activeGroup || null,
                activeDebt: budgetState?.activeDebt || null,
                data: budgetState?.data || null
            };
        }
    },
    actions: {
        setToastNotify({ commit }, toastNotify) {
            if (!!toastNotify) {
                commit("toastNotify", {
                    variant: toastNotify?.type | null,
                    type: toastNotify?.type | "basic",
                    title: toastNotify?.type | null,
                    text: toastNotify?.text | null
                });
            } else {
                commit("toastNotify", null);
            }
        },
        login({ commit, getters }, payload) {
            commit("site", payload.data.site);
            commit("settings", payload.data.settings);
            commit("budgetState", { left: payload.data.settings.view });
            delete payload.data.site;
            delete payload.data.settings;
            commit("user", payload.data);
            commit("tokens", payload.tokens);
            commit("appLoaded", true);
            commit("loggedIn", true);
            commit("locked", null);
        },
        lock({ commit, getters }) {
            commit("locked", getters.user);
            commit("loggedIn", false);
            commit("appLoaded", false);
            commit("site", {});
            commit("settings", {});
            commit("user", {});
            commit("tokens", null);
        },
        logout({ commit }) {
            commit("locked", null);
            commit("loggedIn", false);
            commit("appLoaded", false);
            commit("site", {});
            commit("settings", {});
            commit("user", {});
            commit("tokens", null);
        },
        user({ commit, getters }, payload) {
            const user = getters.user;
            // Update only the given values
            $helper.resetImmutableObject(user, payload);
            commit("user", user);
        },
        setBudgetMonth({ commit }, payload) {
            commit("budgetMonth", payload);
        },
        site({ commit, getters }, payload) {
            const site = getters.site;
            // Update only the given values
            $helper.resetImmutableObject(site, payload);
            commit("site", site);
        },
        async updateSettings({ commit, getters }, payload) {
            const settings = getters.settings;
            // Update only the given values
            $helper.resetImmutableObject(settings, payload);
            await commit("settings", settings);
        },

        /**
         * Create a new budget and add it to the store
         *
         * @param context
         * @returns {Promise<void>}
         */
        async addBudget({ getters, dispatch }) {
            const { data } = await this.$http.addBudget(getters.budgetMonth);
            await dispatch("setBudget", data);
        },

        async deleteBudget({ getters, dispatch }) {
            await $http.deleteBudget(getters.budgetDate);
            await dispatch("setBudget");
        },

        /**
         * Set the budget if payload exists
         *   if not then unset all the valid budget store values
         *
         * @param context
         * @param payload
         * @returns {Promise<void>}
         */
        async setBudget({ commit, dispatch }, payload) {
            if (!payload) {
                const payload = {};
            }
            await commit("budget", payload?.budget);
            await commit("incomes", payload?.incomes);
            await commit("groups", payload?.groups);
            await commit("debts", payload?.debts);
            await commit("stats", payload?.stats);
            await commit("unassigned", payload?.unassigned);
            await dispatch("sortBudgetIncomes");
            await dispatch("sortBudgetGroups");
            await dispatch("sortBudgetEntries");
            await dispatch("sortBudgetEntries");
            await dispatch("refreshIncomeStats");
            await dispatch("refreshGroupStats");
            await dispatch("refreshBudgetToDateStats");
        },

        /**
         * Create the given budget entry and add to the store
         *
         * @param context
         * @param payload
         * @returns {Promise<void>}
         */
        async addBudgetEntry({ commit, getters, dispatch }, payload) {
            try {
                const month = getters.budgetMonth;
                const { data } = await $http.addEntry(
                    getters.budgetMonth,
                    payload,
                    ["income", "group"]
                );
                const incomes = getters.incomes;
                const groups = getters.groups;
                const unassigned = getters.unassigned;

                if (data.budgetIncomeId) {
                    const income = incomes.find(
                        i => i.id === data.budgetIncomeId
                    );
                    income.entries.push(data);
                    sortEntries(income.entries);
                } else {
                    unassigned.income.push(data);
                    sortEntries(unassigned.income);
                }

                if (data.budgetGroupId) {
                    const group = groups.find(i => i.id === data.budgetGroupId);
                    group.entries.push(data);
                    sortEntries(group.entries);
                } else {
                    unassigned.group.push(data);
                    sortEntries(unassigned.group);
                }
                dispatch("refreshBudgetToDateStats");
            } catch (error) {
                return await dispatch("handleError", error);
            }
        },

        /**
         * Create a new budget and refresh the budget afterwards
         *
         * @param context
         * @returns {Promise<void>}
         */
        async createBudget({ commit, getters, dispatch }) {
            await $http.addBudget(getters.budgetMonth, fullBudgetRelations);
            await dispatch("refreshBudget");
        },

        /**
         * Delete the given budget entry and remove from the store
         *
         * @param context
         * @param payload
         * @returns {Promise<*>}
         */
        async deleteBudgetEntry({ commit, getters, dispatch }, payload) {
            try {
                await $http.deleteEntry(getters.budgetMonth, payload.id);

                for (let i = 0; i < getters.incomes.length; i += 1) {
                    getters.incomes[i].entries = getters.incomes[
                        i
                    ].entries.filter(e => e.id !== payload.id);
                }

                for (let i = 0; i < getters.groups.length; i += 1) {
                    getters.groups[i].entries = getters.groups[
                        i
                    ].entries.filter(e => e.id !== payload.id);
                }

                const unassigned = getters.unassigned;
                unassigned.income = unassigned.income.filter(
                    e => e.id !== payload.id
                );
                unassigned.group = unassigned.group.filter(
                    e => e.id !== payload.id
                );
                commit("unassigned", unassigned);
                dispatch("refreshBudgetToDateStats");
            } catch (error) {
                return dispatch("handleError", error);
            }
        },

        /**
         * Update date the budget
         *
         * @param context
         * @returns {Promise<void>}
         */
        async updateBudget({ getters, dispatch }, payload) {
            try {
                await $http.updateBudget(getters.budgetMonth, payload);
                dispatch("refreshBudget");
            } catch (error) {
                return dispatch("handleError", error);
            }
        },

        /**
         * Attempt to refresh the budget using the route year and month
         *   if it doesn't exist then unset the budget
         *   return the error if something other than a 404
         *
         * @param context
         * @returns {Promise<*>}
         */
        async refreshBudget({ getters, commit, dispatch }) {
            const month = getters.budgetMonth;
            try {
                commit("loading", true);
                await dispatch("setBudget", null);
                const { data } = await $http.getBudget(
                    month,
                    fullBudgetRelations
                );
                await dispatch("setBudget", data);
            } catch (error) {
                console.log("error", error);
                if (error.status === 404) {
                    await dispatch("setBudget", null);
                } else {
                    console.log("Store refreshBudget error", error);
                }
            } finally {
                commit("loading", false);
            }
        },

        async handleError({ commit }, error) {
            if (error.status === 422) {
                commit("loading", false);
                return error.errors;
            }
            console.log("Store: handleError: error", error);
            commit("loading", false);
            return;
        },

        /**
         * Update the given budget entry and update entry in the store
         *
         * @param context
         * @param payload
         * @returns {Promise<*>}
         */
        async updateBudgetEntry({ commit, getters, dispatch }, payload) {
            try {
                const { data } = await $http.updateEntry(
                    getters.budgetMonth,
                    payload.id,
                    {
                        amount: payload.amount,
                        autoPay: payload.autoPay,
                        budgetGroupId: payload.budgetGroupId,
                        budgetIncomeId: payload.budgetIncomeId,
                        cleared: payload.cleared,
                        debtId: payload.debtId,
                        dueDay: payload.dueDay,
                        goal: payload.goal,
                        id: payload.id,
                        lastDay: payload.lastDay,
                        name: payload.name,
                        order: payload.order,
                        paid: payload.paid,
                        status: payload.status,
                        url: payload.url
                    },
                    "income,group"
                );
                for (let i = 0; i < getters.incomes.length; i += 1) {
                    let index = getters.incomes[i].entries.findIndex(
                        e => e.id === payload.id
                    );
                    if (index >= 0) getters.incomes[i].entries[index] = data;
                }

                for (let i = 0; i < getters.groups.length; i += 1) {
                    let index = getters.groups[i].entries.findIndex(
                        e => e.id === payload.id
                    );
                    if (index >= 0) getters.groups[i].entries[index] = data;
                }

                await dispatch("refreshBudgetToDateStats");
                await dispatch("refreshIncomeStats");
                await dispatch("refreshGroupStats");
                await dispatch("sortBudgetEntries");
                await dispatch("sortBudgetIncomes");
                await dispatch("sortBudgetGroups");
            } catch (error) {
                return dispatch("handleError", error);
            }
        },

        /**
         * Update the given income and update the incomes store
         *
         * @param context
         * @param payload
         * @returns {Promise<void>}
         */
        async updateBudgetIncome({ getters, commit, dispatch }, payload) {
            try {
                const { data } = await $http.updateIncome(
                    getters.budgetMonth,
                    payload.id,
                    {
                        name: payload.name,
                        dueDay: parseInt(payload.dueDay),
                        amount: parseFloat(payload.amount)
                    },
                    ["entries"]
                );
                for (let i = 0; i < getters.incomes.length; i += 1) {
                    if (getters.incomes[i].id === data.id) {
                        getters.incomes[i] = data;
                        break;
                    }
                }
                dispatch("sortBudgetIncomes");
                dispatch("setBudgetState", { left: true });
            } catch (error) {
                return dispatch("handleError", error);
            }
        },

        async addBudgetIncome({ getters, commit, dispatch }, payload) {
            try {
                const { data } = await $http.addIncome(getters.budgetMonth, {
                    name: payload.name,
                    dueDay: parseInt(payload.dueDay),
                    amount: parseFloat(payload.amount)
                });
                getters.incomes.push(data);
                dispatch("sortBudgetIncomes");
                dispatch("setBudgetState", { left: true });
            } catch (error) {
                console.log("addBudgetIncome");
                return dispatch("handleError", error);
            }
        },

        async deleteBudgetIncome({ getters, commit, dispatch }, payload) {
            try {
                const month = getters.budgetMonth;
                await $http.deleteIncome(month, payload.id);
                commit(
                    "incomes",
                    getters.incomes.filter(g => g.id !== payload.id)
                );
                await dispatch("sortBudgetIncomes");
                await dispatch("setBudgetState", { left: true });
            } catch (error) {
                return dispatch("handleError", error);
            }
        },

        async updateBudgetGroup({ getters, commit, dispatch }, payload) {
            try {
                const month = getters.budgetMonth;
                const groups = getters.groups;
                const { data } = await $http.updateBudgetGroup(
                    month,
                    payload.id,
                    {
                        name: payload.name
                    },
                    ["entries"]
                );

                for (let i = 0; i < groups.length; i += 1) {
                    if (groups[i].id === data.id) {
                        groups[i] = data;
                        break;
                    }
                }
                dispatch("sortBudgetGroups");
                dispatch("setBudgetState", { left: true });
            } catch (error) {
                return dispatch("handleError", error);
            }
        },

        async addBudgetGroup({ getters, commit, dispatch }, payload) {
            try {
                const groups = getters.groups;
                const month = getters.budgetMonth;
                const { data } = await $http.addBudgetGroup(
                    month,
                    { name: payload.name },
                    ["entries"]
                );
                groups.push(data);
                await commit("groups", groups);
                await dispatch("sortBudgetGroups");
                await dispatch("setBudgetState", { left: true });
            } catch (error) {
                return dispatch("handleError", error);
            }
        },

        async deleteBudgetGroup({ getters, commit, dispatch }, payload) {
            try {
                await $http.deleteBudgetGroup(getters.budgetMonth, payload.id);
                const groups = getters.groups.filter(g => g.id !== payload.id);
                commit("groups", groups);
                await dispatch("sortBudgetGroups");
                await dispatch("setBudgetState", { left: true });
            } catch (error) {
                return dispatch("handleError", error);
            }
        },

        async addDebt({ getters, commit, dispatch }, payload) {
            try {
                const { data } = await $http.addDebt(payload);
                getters.debts.push(data);
            } catch (error) {
                return dispatch("handleError", error);
            }
        },

        async updateDebt({ getters, commit, dispatch }, payload) {
            try {
                const { data } = await $http.updateDebt(payload.id, payload);
                const debts = getters.debts;
                for (let i = 0; i < debts.length; i += 1) {
                    if (debts[i].id === data.id) {
                        debts[i] = data;
                        break;
                    }
                }
                dispatch("sortBudgetGroups");
            } catch (error) {
                return dispatch("handleError", error);
            }
        },

        async deleteDebt({ getters, commit, dispatch }, payload) {},

        sortBudgetIncomes({ getters, commit }) {
            const incomes = getters.incomes;
            sortEntries(incomes);
            commit("incomes", incomes);
        },

        sortBudgetGroups({ getters, commit }) {
            const groups = getters.groups;
            sortEntries(groups);
            commit("groups", groups);
        },

        /**
         * Sort all of the entries by dueDay
         *
         * @param context
         */
        sortBudgetEntries({ getters, commit }) {
            const incomes = getters.incomes;
            const groups = getters.groups;
            const unassigned = getters.unassigned;

            incomes.forEach(income => {
                sortEntries(income.entries);
            });
            groups.forEach(group => {
                sortEntries(group.entries);
            });
            sortEntries(unassigned.income);
            sortEntries(unassigned.group);

            commit("incomes", incomes);
            commit("groups", groups);
            commit("unassigned", unassigned);
        },

        async refreshDebts({ getters, commit, dispatch }) {
            try {
                const { data } = await $http.getDebts(["actions"]);
                commit("debts", data);
            } catch (error) {
                return dispatch("handleError", error);
            }
        },

        refreshSectionStats({ getters, commit }, section) {
            let collection = null;

            if (section === "incomes") {
                collection = getters.incomes;
            } else if (section === "groups") {
                collection = getters.groups;
            }

            if (!collection) {
                console.log(
                    "Store: refreshSectionStats: invalid collection type",
                    payload
                );
            }

            if (!Array.isArray(collection)) {
                return;
            }
            const stats = [];
            collection.forEach(item => {
                const id = item.id;
                stats[id] = {
                    expenses: 0,
                    outStanding: 0,
                    leftOver: 0
                };
                item.entries.forEach(entry => {
                    const amount = parseFloat(entry.amount);
                    stats[id].expenses += amount;
                    if (!entry.cleared) {
                        stats[id].outStanding += amount;
                    }
                });

                if (section === "incomes") {
                    const amount = parseFloat(item.amount);
                    stats[id].leftOver = amount - stats[id].expenses;
                }
            });

            const statsType =
                section === "incomes" ? "incomeStats" : "groupStats";
            commit(statsType, stats);
        },

        refreshIncomeStats({ dispatch }) {
            dispatch("refreshSectionStats", "incomes");
        },

        refreshGroupStats({ dispatch }) {
            dispatch("refreshSectionStats", "groups");
        },

        refreshBudgetToDateStats({ commit, getters, dispatch }) {
            if (!getters.budget) {
                return;
            }
            const today = moment().format("D");
            let outStanding = 0;
            let id = null;
            const balance = parseFloat(getters.budget.currentBalance);
            getters.incomes.forEach(income => {
                if (today >= income.dueDay) {
                    id = income.id;
                    income.entries.forEach(entry => {
                        if (!entry.cleared) {
                            outStanding += parseFloat(entry.amount);
                        }
                    });
                }
            });

            const leftOver = balance - outStanding;
            commit("budgetToDateStats", { id, outStanding, leftOver });
        },
        appLoaded({ commit }, payload) {
            commit("appLoaded", payload);
        },
        budgetDate({ commit }, date) {
            commit("budgetDate", date);
        },
        async refreshStats({ commit, getters }) {
            const budget = getters.budget;
            const { data } = await $http.budgetStats(budget.month);
            commit("stats", data);
        },
        setBudgetState({ commit, getters }, budgetState) {
            const original = getters.budgetState;
            for (const key in budgetState) {
                if (budgetState[key] === true) {
                    budgetState[key] = original[key];
                }
            }
            commit("budgetState", budgetState);
        }
    }
});
