<template>
    <div>
        <ub-button v-if="cacheProgress === 'cleared'"
                   @mousedown="setCacheProgress('none')"
                   @mouseup="throttleUpdate"
                   size="sm"
                   icon="check"
                   variant="success"/>
        <ub-button v-else-if="cacheProgress === 'paid'"
                   @mousedown="setCacheProgress('cleared')"
                   @mouseup="throttleUpdate"
                   size="sm"
                   icon="dotsHorizontal"
                   variant="warning"/>
        <ub-button v-else-if="cacheProgress === 'goal'"
                   @mousedown="setCacheProgress('paid')"
                   @mouseup="throttleUpdate"
                   size="sm"
                   icon="dotsHorizontal"
                   variant="danger"/>
        <ub-button v-else
                   @mousedown="setCacheProgress('goal')"
                   @mouseup="throttleUpdate"
                   size="sm"
                   icon="dotsHorizontal"
                   variant="secondary"
                   outline/>
    </div>
</template>

<script>
import {debounce, throttle} from "lodash";

export default {
    props: {
        progress: {
            type: String,
            default: '',
            validator: (value) => {
                return ['cleared', 'paid', 'goal', 'none'].includes(value)
            }
        }
    },
    data() {
        return {
            cacheProgress: '',
            throttleUpdate: debounce(this.update, 500)
        }
    },
    methods: {
        setCacheProgress(status) {
            console.log('setCacheProgress', this.cacheProgress)
            this.cacheProgress = status
        },
        update() {
            console.log('update', this.cacheProgress)
            this.$emit('update', this.cacheProgress)
        }
    },
    mounted() {
        this.cacheProgress = this.progress
    }
}
</script>
