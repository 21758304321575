<template>
    <section>
        <budget-header class="mb-4" />
        <div class="px-4 pb-4">
            <div v-if="!budget && !isLoading">
                <budget-create />
            </div>
            <budget-divided v-else>
                <template v-slot:left>
                    <div
                        :class="{ 'hidden md:inline': !budgetState.left }"
                        class="h-full"
                    >
                        <div class="h-full">
                            <budget-right-header>
                                <template v-slot:left>
                                    <ub-button
                                        @click="viewIncomes"
                                        size="sm"
                                        icon="currencyDollar"
                                        :variant="
                                            budgetState.left === 'incomes'
                                                ? 'primary'
                                                : 'secondary'
                                        "
                                        outline
                                    />
                                    <ub-button
                                        @click="viewGroups"
                                        size="sm"
                                        icon="viewBoard"
                                        :variant="
                                            budgetState.left === 'groups'
                                                ? 'success'
                                                : 'secondary'
                                        "
                                        outline
                                    />
                                    <ub-button
                                        @click="viewDebts"
                                        size="sm"
                                        icon="creditCard"
                                        :variant="
                                            budgetState.left === 'debts'
                                                ? 'success'
                                                : 'secondary'
                                        "
                                        outline
                                    />
                                </template>
                                <template v-slot:right>
                                    <ub-button
                                        v-if="budgetState.left === 'incomes'"
                                        @click="createIncome"
                                        size="sm"
                                        icon="plus"
                                        variant="secondary"
                                        outline
                                    />
                                    <ub-button
                                        v-if="budgetState.left === 'groups'"
                                        @click="createGroup"
                                        size="sm"
                                        icon="plus"
                                        variant="secondary"
                                        outline
                                    />
                                    <ub-button
                                        @click="loadBudget"
                                        size="sm"
                                        icon="refresh"
                                        variant="secondary"
                                        outline
                                    />
                                </template>
                            </budget-right-header>
                            <incomes
                                v-if="
                                    budgetState.left === 'incomes' ||
                                        !budgetState.left
                                "
                            />
                            <groups v-if="budgetState.left === 'groups'" />
                            <debts v-if="budgetState.left === 'debts'" />
                        </div>
                    </div>
                </template>
                <template v-slot:right>
                    <div
                        :class="{ 'hidden md:inline': !!budgetState.left }"
                        class="h-full"
                    >
                        <budget-stats v-if="!budgetState.right" />
                        <debt-stats
                            v-if="budgetState.right === 'stats-debts'"
                        />
                        <income-form
                            v-if="budgetState.right === 'modify-income'"
                            class="object-top"
                            :income="budgetState.data"
                        />
                        <group-form
                            v-if="budgetState.right === 'modify-group'"
                            class="object-top"
                            :group="budgetState.data"
                        />
                        <entry-form
                            v-if="budgetState.right === 'modify-entry'"
                            class="object-top"
                            :entry="budgetState.data"
                        />
                        <debt-details
                            v-if="budgetState.right === 'details-debt'"
                            class="object-top"
                            :debt="budgetState.data"
                        />
                        <debt-form
                            v-if="budgetState.right === 'modify-debt'"
                            class="object-top"
                            :debt="budgetState.data"
                        />
                    </div>
                </template>
            </budget-divided>
        </div>
    </section>
</template>

<script>
import BudgetHeader from "@/views/dashboard/budget/BudgetHeader";
import BudgetRightHeader from "@/views/dashboard/budget/BudgetRightHeader";
import BudgetSidebar from "@/components/budget/BudgetSidebar";
import BudgetStats from "@/views/dashboard/budget/BudgetStats";
import Incomes from "@/views/dashboard/budget/Incomes";
import IncomeForm from "@/views/dashboard/budget/IncomeForm";
import Groups from "@/views/dashboard/budget/Groups";
import GroupForm from "@/views/dashboard/budget/GroupForm";
import EntryForm from "@/views/dashboard/budget/EntryForm";
import DebtForm from "@/views/dashboard/budget/DebtForm";
import DebtStats from "@/views/dashboard/budget/DebtStats";
import DebtDetails from "@/views/dashboard/budget/DebtDetails";
import BudgetCreate from "@/views/dashboard/budget/BudgetCreate";
import Modal from "@/components/ui/modal/Modal";
import BudgetDivided from "@/components/budget/BudgetDivided";
import Debts from "@/views/dashboard/budget/Debts";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        BudgetHeader,
        BudgetRightHeader,
        BudgetSidebar,
        BudgetStats,
        BudgetDivided,
        Incomes,
        IncomeForm,
        Groups,
        GroupForm,
        EntryForm,
        DebtForm,
        DebtStats,
        DebtDetails,
        BudgetCreate,
        Modal,
        Debts
    },

    computed: {
        ...mapGetters([
            "budget",
            "incomes",
            "groups",
            "debts",
            "settings",
            "budgetState",
            "isLoading"
        ])
    },

    watch: {
        $route() {
            this.loadBudget();
        }
    },

    methods: {
        ...mapActions(["setBudgetState", "refreshBudget", "refreshDebts"]),
        clearState(left, right = null) {
            const state = {};
            if (left) {
                state.left = left;
            } else {
                state.left = this.budgetState.left;
            }
            if (right) {
                state.right = right;
            }
            this.setBudgetState(state);
        },
        async viewIncomes() {
            this.clearState("incomes");
            await this.$http.updateSettings({ view: "incomes" });
        },
        async viewGroups() {
            this.clearState("groups");
            await this.$http.updateSettings({ view: "groups" });
        },
        async viewDebts() {
            this.clearState("debts", "stats-debts");
            await this.refreshDebts();
            await this.$http.updateSettings({ view: "debts" });
        },
        createIncome() {
            this.setBudgetState({ left: true, right: "modify-income" });
        },
        createGroup() {
            this.setBudgetState({ left: true, right: "modify-group" });
        },
        async loadBudget() {
            await this.refreshBudget();
        }
    },

    async mounted() {
        if (this.budgetState.left === "debts") {
            this.budgetState.right = "stats-debts";
        }
        await this.loadBudget();

        console.log("budget", this.budget);
    }
};
</script>
