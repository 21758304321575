<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-200 py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-md w-full">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>
