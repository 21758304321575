<template>
    <span class="inline-flex items-center rounded-full px-2.5 py-0.5 font-medium"
          :class="classes">
        <span v-if="label">
            {{ label }}
        </span>
        <span v-else>
            <slot/>
        </span>
    </span>
</template>

<script>
export default {
    props: {
        label: {
            type: String
        },

        size: {
            type: String,
            default: 'md',
            validator: value => {
                return ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl'].includes(value)
            }
        },
        xs: {type: Boolean},
        sm: {type: Boolean},
        md: {type: Boolean},
        lg: {type: Boolean},
        xl: {type: Boolean},

        color: {
            type: String,
            default: null,
            validator: value => {
                return ['gray', 'red', 'yellow', 'green', 'blue', 'indigo', 'purple', 'pink'].includes(value)
            }
        },
        red: {type: Boolean},
        yellow: {type: Boolean},
        green: {type: Boolean},
        blue: {type: Boolean},
        indigo: {type: Boolean},
        purple: {type: Boolean},
        pink: {type: Boolean},
    },
    data() {
        return {
            bg: '100',
            text: '800'
        }
    },
    computed: {
        getColor() {
            if (this.color) {
                return this.color
            }
            if (this.red) return 'red'
            if (this.yellow) return 'yellow'
            if (this.green) return 'green'
            if (this.blue) return 'blue'
            if (this.indigo) return 'indigo'
            if (this.purple) return 'purple'
            if (this.pink) return 'pink'
            return 'gray'
        },
        getSize() {
            if (this.size) {
                return this.size
            }
            if (this.xs) return 'xs'
            if (this.sm) return 'sm'
            if (this.lg) return 'lg'
            if (this.xl) return 'xl'
            return null
        },
        classes() {
            const classes = []

            const color = this.getColor
            if (color) {
                classes.push(`bg-${color}-${this.bg}`)
                classes.push(`text-${color}-${this.text}`)
            }

            const size = this.getSize
            if (size) {
                classes.push(`text-${size}`)
            }

            return classes
        }
    }
}
</script>
