<template>
    <div>
        <debt-action-balances :debt="debt" class="pb-4" />
        <debt-action-payments :debt="debt" />
    </div>
</template>

<script>
import DebtActionPayments from "@/views/dashboard/budget/DebtActionPayments";
import DebtActionBalances from "@/views/dashboard/budget/DebtActionBalances";

export default {
    components: {
        DebtActionPayments,
        DebtActionBalances
    },
    props: {
        debt: {
            type: Object
        }
    }
}
</script>
