import InvalidCredentialsException from '@/scripts/http/exceptions/InvalidCredentialsException';
import UserNotVerifiedException from '@/scripts/http/exceptions/UserNotVerifiedException';
import NotFoundHttpException from '@/scripts/http/exceptions/NotFoundHttpException';
import ValidationException from '@/scripts/http/exceptions/ValidationException';
import Generic400Exception from '@/scripts/http/exceptions/Generic400Exception';
import Generic500Exception from '@/scripts/http/exceptions/Generic500Exception';

/**
 * Check if there error is an exception
 * 
 * @param {*} error 
 * @returns 
 */
export const hasException = (error) => {
    const exception = error?.response?.data?.error?.exception;
    return exception && typeof exception === 'string' ? exception : null;
}

/**
 * Handle an exception if it exists
 * 
 * @param {*} error 
 * @returns 
 */
export const handleException = (error) => {
    const status = error.response.status;
    const exception = hasException(error);
    if (exception) {
        switch(exception) {
            case 'InvalidCredentialsException':
                return InvalidCredentialsException(error, exception);
            case 'UserNotVerifiedException':
                return UserNotVerifiedException(error, exception);
            case 'NotFoundHttpException':
                return NotFoundHttpException(error, exception);
            case 'ValidationException':
                return ValidationException(error, exception);
            case status >= 400 && status < 500:
                return Generic400Exception(error, exception);
            case status >= 500:
                return Generic500Exception(error, exception);
        }
    }

    return {};
}
