<template>
    <div>
        <list-container>
            <div class="sticky top-0">
                <list-header>
                    <list-title class="pl-4">Debts</list-title>
                    <template>
                        <list-right-button icon="plus" @click="create"/>
                    </template>
                </list-header>
            </div>
            <debt-entry v-for="(debt, index) in debts"
                   :key="`debt-${index}`"
                   :debt="debt"/>
        </list-container>
    </div>
</template>

<script>
import DebtEntry from "@/views/dashboard/budget/DebtEntry";
import DebtNonFound from "@/views/dashboard/budget/DebtNonFound";
import ListContainer from "@/components/layouts/list/ListContainer"
import ListHeader from "@/components/layouts/list/ListHeader"
import ListCollapseButton from "@/components/layouts/list/ListCollapseButton"
import ListTitle from "@/components/layouts/list/ListTitle";
import ListRightButton from "@/components/layouts/list/ListRightButton";
import ListFooter from "@/components/layouts/list/ListFooter";
import ListStatsFooter from "@/components/layouts/list/ListStatsFooter";
import ListSubHeader from "@/components/layouts/list/ListSubHeader";
import ListTitleAmount from "@/components/layouts/list/ListTitleAmount";
import {mapGetters, mapActions} from 'vuex'

export default {
    components: {
        DebtEntry,
        DebtNonFound,
        ListContainer,
        ListHeader,
        ListTitle,
        ListCollapseButton,
        ListRightButton,
        ListFooter,
        ListStatsFooter,
        ListSubHeader,
        ListTitleAmount
    },
    computed: {
        ...mapGetters(['debts', 'debtTypes']),
    },

    methods: {
        ...mapActions(['refreshDebts', 'setBudgetState']),
        create() {
            this.setBudgetState({
                left: true,
                right: 'modify-debt',
                data: {
                    name: '',
                    active: true,
                    type: 'credit_card',
                    creditLimit: '',
                    currentBalance: '',
                    interestRate: '',
                }
            })
        },
        filter() {

        }
    },
    mounted() {
        console.log('debts', this.debts)
    }
}
</script>
