<template>
    <div>
        <list-container>
            <div class="sticky top-0">
                <list-header :active="isActive">
                    <list-collapse-button v-model="collapsed"/>
                    <list-title :pointer="isAssigned" @click="modify">
                        {{ group.name || 'Unassigned' }}
                    </list-title>
                    <list-right-button v-if="isAssigned" icon="plus" @click="createEntry"/>
                </list-header>
                <list-sub-header :entries-count="this.group.entries.length" :collapsed="collapsed"/>
            </div>
            <draggable handle=".entry-handle" :list="group.entries" v-bind="dragOptions" @change="dragChanged">
                <entry v-for="(entry, index) in group.entries"
                       v-if="entryVisibility(entry)"
                       :key="`entry-${index}`"
                       :entry="entry"
                       hide-progress/>
            </draggable>
            <list-footer v-if="isAssigned">
                <list-stats-footer :group-id="group.id"/>
            </list-footer>
        </list-container>
        <due-day-update-modal :entry="changeDueDay" @completed="changeDueDay = null"/>
    </div>
</template>

<script>
    import DueDay from '@/components/ui/DueDay'
    import Draggable from 'vuedraggable'
    import Entry from '@/views/dashboard/budget/Entry'
    import DueDayUpdateModal from "@/views/dashboard/budget/DueDayUpdateModal";
    import ListContainer from "@/components/layouts/list/ListContainer"
    import ListHeader from "@/components/layouts/list/ListHeader"
    import ListCollapseButton from "@/components/layouts/list/ListCollapseButton"
    import ListTitle from "@/components/layouts/list/ListTitle";
    import ListRightButton from "@/components/layouts/list/ListRightButton";
    import ListFooter from "@/components/layouts/list/ListFooter";
    import ListStatsFooter from "@/components/layouts/list/ListStatsFooter";
    import ListSubHeader from "@/components/layouts/list/ListSubHeader";
    import {mapGetters, mapActions} from 'vuex'

    export default {
        components: {
            Draggable,
            Entry,
            DueDayUpdateModal,
            DueDay,
            ListContainer,
            ListHeader,
            ListTitle,
            ListCollapseButton,
            ListRightButton,
            ListFooter,
            ListStatsFooter,
            ListSubHeader
        },
        props: {
            group: {
                type: Object
            }
        },
        data () {
            return {
                drag: false,
                collapsed: 2,
                balances: {},
                changeDueDay: null,
                draggableObject: []
            }
        },
        computed: {
            ...mapGetters(['budgetDate', 'budgetState']),
            isAssigned() {
                return !!this.group.id
            },
            isActive() {
                if (this.isAssigned) {
                    console.log('this.budgetState', this.budgetState)
                    return this.budgetState.activeGroup === this.group.id
                }
                return false
            },
            dragOptions() {
                return {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                }
            }
        },
        methods: {
            ...mapActions(['setBudgetState', 'updateBudgetEntry']),
            modify () {
                if (!this.isAssigned || this.budgetState.activeEntry) {
                    return;
                }

                if (this.budgetState.activeGroup && this.budgetState.activeGroup !== this.group.id) {
                    return;
                }

                this.setBudgetState({
                    left: 'groups',
                    right: 'modify-group',
                    activeGroup: this.group.id,
                    data: this.group
                });
            },
            entryVisibility (entry) {
                if (this.collapsed === 1) {
                    return true
                } else if (this.collapsed === 2) {
                    return !entry.cleared
                }

                return false;
            },
            createEntry () {
                this.setBudgetState({
                    right: 'modify-entry',
                    activeGroup: this.group.id,
                    data: {
                        id: null,
                        name: '',
                        autoPay: false,
                        dueDay: 1,
                        amount: 0.00,
                        budgetIncomeId: null,
                        budgetGroupId: group.id,
                        url: ''
                    }
                });
            },
            async initialCollapse () {
                let cleared = false
                for (let i = 0; i < this.group.entries.length; i++) {
                    if (this.group.entries[i].cleared === true) {
                        cleared++
                    }
                }

                if (cleared === this.group.entries.length) {
                    this.collapsed = 3
                } else if (cleared >= 1) {
                    this.collapsed = 2
                } else {
                    this.collapsed = 1
                }
            },
            async modifyEntry (entry) {
                try {
                    const { data } = await this.$http.getEntry(this.budgetDate, entry.id)
                    this.$emit('modify-entry', data)
                } catch (error) {
                    console.log('error', error)
                }
            },
            dragChanged (evt) {
                if (evt.added) {
                    this.addedTo(evt.added.newIndex, evt.added.element)
                } else if (evt.moved) {
                    this.moveTo(evt.moved.oldIndex, evt.moved.newIndex, evt.moved.element)
                }
            },
            async addedTo (newIndex, entry) {
                try {
                    entry.budgetGroupId = this.group.id
                    await this.updateBudgetEntry(entry)
                    this.collapsed = 1
                } catch (error) {
                    console.log('Group: addedTo: error', error)
                }
            },
            async moveTo(oldIndex, newIndex, entry) {
                entry.oldIndex = oldIndex
                entry.newIndex = newIndex
                this.changeDueDay = entry

                // try {
                //     entry.budgetGroupId = this.group.id
                //     await this.updateBudgetEntry(entry)
                //     this.collapsed = 1
                // } catch (error) {
                //     console.log('Group: addedTo: error', error)
                // }


                // const ids = this.group.entries.map(e => e.id)
                // try {
                //     await this.$http.updateEntry(this.budgetDate, entry.id, { order: (newIndex + 1) })
                //     // await this.$http.updateEntry(this.budgetDate, entry.id, this.group.id, { order: ids })
                //     entry.groupId = this.group.id
                //     // If entry as been added then un-collapse so results can be seen
                //     this.collapsed = false
                // } catch (error) {
                //     console.log('error', error)
                // }
            }
        },
        async mounted () {
            await this.initialCollapse()
        }
    }
</script>

<style lang="scss" scoped>
    .flip-list-move {
        transition: transform 0.5s;
    }
    .no-move {
        transition: transform 0s;
    }
    .ghost {
        visibility: hidden;
    }
    .list-group {
        min-height: 20px;
    }
    .list-group-item {
        cursor: move;
    }
    .list-group-item i {
        cursor: pointer;
    }
</style>
