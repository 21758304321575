<template>
    <div>
        <budget-right-header>
            <template v-slot:left>
                <ub-button v-if="income.id" @click="deleteIncome" outline variant="danger" icon="trash"
                           class="float-left" size="sm"></ub-button>
            </template>
            <template v-slot:right>
                <ub-button variant="secondary" @click="cancel" outline size="sm">Cancel</ub-button>
                <ub-button @click="save" size="sm">Save</ub-button>
            </template>
        </budget-right-header>
        <div class="bg-gray-100 border border-gray-300 rounded-md shadow-md p-4 grid grid-cols-2 gap-6">
            <div class="col-span-2 text-lg text-center font-bold">Income Details</div>
            <div class="col-span-2">
                <f-input label="Income Name"
                         placeholder="entry name"
                         v-model="income.name"
                         :error="hasError('name')"/>
            </div>
            <div class="col-span-1">
                <due-day-picker v-model="income.dueDay" :date="budgetMonth"></due-day-picker>
            </div>
            <div class="col-span-1">
                <amount-input v-model="income.amount" :error="hasError('amount')"/>
            </div>
        </div>
        <modal v-if="showDelete" variant="danger" title="Are you sure?" confirm-label="Yes, Delete!"
               cancel-label="Oops! No" @confirm="deleteConfirmed" @cancel="deleteCanceled">
            Do you really want to delete this income? It can't be undone.
        </modal>
    </div>
</template>

<script>
import moment from 'moment'
import Modal from "@/components/ui/modal/Modal";
import BudgetRightHeader from '@/views/dashboard/budget/BudgetRightHeader'
import {mapGetters, mapActions} from 'vuex'
import DueDayPicker from '@/views/dashboard/budget/DueDayPicker'
import AmountInput from "@/components/ui/AmountInput";

export default {
    components: {
        Modal,
        BudgetRightHeader,
        DueDayPicker,
        AmountInput
    },
    data() {
        return {
            errors: null,
            income: {
                id: null,
                name: '',
                dueDay: '1',
                amount: '0.00'
            },
            showDelete: false,
            originalIncome: null
        }
    },
    computed: {
        ...mapGetters([
            'budget',
            'budgetState',
            'budgetMonth'
        ]),
        date() {
            let dueDay = this.income.dueDay ? this.income.dueDay : '1'
            const date = moment(this.budget.month, "YYYY-M-DD").day(dueDay)
            const year = date.format('YYYY')
            const month = date.format('MM');
            return moment(`${year}-${month}-${dueDay}`, "YYYY-M-DD")
        },
        dueDaySuffix() {
            const day = this.date.format('Do')
            return day.slice(-2)
        },
        hasErrors() {
            return !!this.errors
        }
    },
    methods: {
        ...mapActions([
            'setBudgetState',
            'updateBudgetIncome',
            'addBudgetIncome',
            'deleteBudgetIncome',
            'refreshBudget'
        ]),
        hasError(key) {
            if (this.errors && key in this.errors) {
                return this.errors[key]
            }
            return null
        },
        async save() {
            this.errors = null
            if (this.income.id) {
                this.errors = await this.updateBudgetIncome(this.income)
                await this.refreshBudget()
                return;
            } else {
                this.errors = await this.addBudgetIncome(this.income)
                if (!this.hasErrors) {
                    await this.setBudgetState({left: 'incomes'})
                }
            }
            await this.refreshBudget()
        },
        cancel() {
            this.$helper.resetImmutableObject(this.income, this.originalIncome)
            this.setBudgetState({left: 'incomes'})
        },
        deleteIncome() {
            this.showDelete = true
        },
        async deleteConfirmed() {
            await this.deleteBudgetIncome({id: this.income.id})
            this.showDelete = false
        },
        deleteCanceled() {
            this.showDelete = false
        },
    },
    mounted() {
        if (this.budgetState.data?.id) {
            this.income = this.budgetState.data
        }
        this.originalIncome = {...this.income}
    }
}

</script>
