<template>
    <div>
        <f-form :prevent="save">
            <budget-right-header>
                <template v-slot:left>
                    <ub-button v-if="entry.id"
                               @click="showDelete = true"
                               outline variant="danger" size="sm"
                               icon="trash"
                               class="float-left"/>
                </template>
                <template v-slot:right>
                    <ub-button variant="secondary" @click="cancel" size="sm" outline>Cancel</ub-button>
                    <ub-button type="submit" size="sm">Save</ub-button>
                </template>
            </budget-right-header>

            <div class="bg-gray-100 border border-gray-300 rounded-md shadow-md p-4 grid grid-cols-2 gap-6">
                <div class="col-span-2 text-lg text-center font-bold">
                    <template v-if="entry.id">Edit Entry</template>
                    <template v-else>Create Entry</template>
                </div>
                <div class="col-span-2">
                    <f-input label="Entry Name" placeholder="entry name" v-model="entry.name" :error="hasError('name')">
                        <template v-slot:right-add-on>
                            <div class="flex items-center">
                                <f-switch v-model="entry.autoPay" @input="setAutoPay"/>
                            </div>
                        </template>
                    </f-input>
                </div>
                <div class="col-span-1 place-content-end">
                    <due-day-picker v-model="entry.dueDay"
                                    :date="budgetMonth"
                                    show-checkbox
                                    :checked="lastDayChecked"
                                    @checked="dueDayChecked"/>
                </div>
                <div class="col-span-1">
                    <f-input-currency 
                        id="entryAmountCurrency"
                        v-model="entry.amount"
                        label="Amount"
                        placeholder="0.00"
                        left-add-on="$"
                        :error="hasError('amount')"
                    />
                </div>
                <div class="col-span-2">
                    <ub-select label="Income"
                               v-model="entry.budgetIncomeId"
                               :options="incomes"
                               id-key="id"
                               label-key="name"
                               placeholder="select income">
                        <ub-select-divider></ub-select-divider>
                        <ub-select-option @click="entry.budgetIncomeId = null" :value="null" label="none"/>
                    </ub-select>
                </div>
                <div class="col-span-2">
                    <ub-select label="Group"
                               v-model="entry.budgetGroupId"
                               :options="groups"
                               id-key="id"
                               label-key="name"
                               placeholder="select group">
                        <ub-select-divider></ub-select-divider>
                        <ub-select-option @click="entry.budgetGroupId = null" :value="null" label="none"/>
                    </ub-select>
                </div>
                <div class="col-span-2">
                    <ub-select label="Link Debt"
                               v-model="entry.debtId"
                               :options="debts"
                               id-key="id"
                               label-key="name"
                               placeholder="select debt">
                        <ub-select-divider></ub-select-divider>
                        <ub-select-option @click="entry.debtId = null" :value="null" label="none"/>
                    </ub-select>
                </div>
                <div class="col-span-2">
                    <f-input label="Url"
                             placeholder="http://"
                             v-model="entry.url"
                             :error="hasError('url')"/>
                </div>
            </div>
        </f-form>
        <modal v-if="showDelete"
               variant="danger"
               title="Are you sure?"
               confirm-label="Yes, Delete!"
               cancel-label="Oops! No" @confirm="deleteEntryConfirmed" @cancel="deleteEntryCanceled">
            Do you really want to delete this entry? It can't be undone.
        </modal>
    </div>
</template>

<script>
import moment from "moment";
import Modal from "@/components/ui/modal/Modal";
import DueDayPicker from '@/views/dashboard/budget/DueDayPicker'
import BudgetRightHeader from '@/views/dashboard/budget/BudgetRightHeader'
import {mapGetters, mapActions} from 'vuex'

export default {
    components: {
        Modal,
        DueDayPicker,
        BudgetRightHeader
    },
    props: {
        entry: {
            type: Object
        }
    },
    data() {
        return {
            errors: null,
            showDelete: false,
            groupSelectedObject: null,
            originalEntry: null
        }
    },
    computed: {
        ...mapGetters(['budgetState', 'budgetMonth', 'incomes', 'groups', 'debts']),
        date() {
            const year = this.$route.params.year
            const month = this.$route.params.month
            let dueDay = this.entry.dueDay ? this.entry.dueDay : '01'
            return moment(`${year}-${month}-${dueDay}`, "YYYY-M-DD")
        },
        hasErrors() {
            return !!this.errors
        },
        dueDaySuffix() {
            const day = this.date.format('Do')
            return day.slice(-2)
        },
        lastDayChecked() {
            return !!this.entry.lastDay
        }
    },
    methods: {
        ...mapActions([
            'updateBudgetEntry',
            'addBudgetEntry',
            'deleteBudgetEntry',
            'refreshStats',
            'setBudgetState',
            'setToastNotify'
        ]),
        hasError(key) {
            if (this.errors && key in this.errors) {
                return this.errors[key]
            }
            return null
        },
        cancel() {
            this.$helper.resetImmutableObject(this.entry, this.originalEntry)
            this.setBudgetState({left: true})
        },
        dueDayChecked(checked) {
            this.entry.lastDay = checked
            if (checked) {
                this.entry.dueDay = moment(this.budgetMonth).endOf('month').format('D')
            }
        },
        async save() {
            this.errors = null
            if (this.entry.id) {
                // this.entry.amount = this.$helper.cleanCurrency(this.entry.amount);
                this.errors = await this.updateBudgetEntry(this.entry)
                this.setToastNotify({
                    variant: 'success',
                    title: 'Entry Saved',
                    text: `The entry ${this.entry.name} has been saved`
                })
            } else {
                this.errors = await this.addBudgetEntry(this.entry)
            }
            if (!this.hasErrors) {
                this.setBudgetState({left: true})
            }
        },
        async deleteEntryConfirmed() {
            try {
                await this.deleteBudgetEntry(this.entry)
                this.showDelete = false
                this.setBudgetState({left: true})
            } catch (error) {
                console.log('Entry Delete Error', error)
            }
        },
        deleteEntryCanceled() {
            this.showDelete = false
            this.setBudgetState({left: true})
        },
        setAutoPay(value) {
            this.$emit('checked', value)
        }
    },
    created() {
        this.originalEntry = {...this.budgetState.data}
    }
}
</script>
