/**
 * Encode password before sending over API
 *
 * @param password
 */
const encodePassword = (password) => {
    const pre = Math.random().toString(36).slice(3, 6);
    const post = Math.random().toString(36).slice(1, 4);
    return btoa(`${pre}${password}${post}`);
}

/**
 * Encode password before sending over API
 *
 * @param password
 */
const decodePassword = (password) => {
    return atob(password).slice(3, password.length - 3);
}

/**
 * Take in an immutable object and update with values
 * from the original
 *
 * @param immutable object that can't be reset
 * @param original object container original values
 */
const resetImmutableObject = (immutable, original) => {
    for (const [key, value] of Object.entries(original)) {
        immutable[key] = value
    }
}

/**
 * Take in a value and clean it to match currency without the symbol
 *
 * @param value
 *
 * @returns string
 */
const cleanCurrency = (value) => {
    return Number.isInteger(value) ? value.toFixed(2) : value
}

/**
 * Format a currency string
 *
 * @param value
 * @param includeSymbol
 *
 * @returns string
 */
const currency = (value, includeSymbol = true) => {
    const options = {
        currency: 'USD',
        minimumFractionDigits: 2
    }

    if (includeSymbol) {
        options.style = 'currency'
    }

    const formatter = new Intl.NumberFormat('en-US', options)

    return formatter.format(value)
}

/**
 * Define methods to be used outside of vue components
 */
const context = {
    encodePassword,
    decodePassword,
    resetImmutableObject,
    cleanCurrency,
    currency
}

export const $helper = context

/**
 * Define the $helper plugin
 */
const Helper = {
    install(Vue) {
        Vue.prototype.$helper = context
    }
}

export default Helper
