<template>
    <div
        :class="state"
        class="flex border-b rounded-md rounded-b-none cursor-pointer m-1 pb-1"
    >
        <div class="flex-none border-r px-1 pt-3">
            <icon
                :name="icon"
                stroke
                size="6"
                class="entry-handle cursor-move text-gray-400 hover:text-gray-700"
            ></icon>
        </div>
        <div @click="details" class="flex-1 px-2 pt-1">
            {{ debt.name }}
            <span class="text-gray-400 text-xs">
                {{ debt.interestRate | percent }}
            </span>
            <div class="text-xs text-gray-500">
                Credit Limit: {{ debt.creditLimit | currency }}
            </div>
        </div>
        <div @click="details" class="flex-none text-right px-2 pt-1">
            {{ debt.currentBalance | currency }}
            <div class="text-xs text-gray-500">
                {{ debtType.name }}
            </div>
        </div>
    </div>
</template>

<script>
import Modal from "@/components/ui/modal/Modal";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        Modal
    },
    props: {
        debt: {
            type: Object
        }
    },
    computed: {
        ...mapGetters(["budgetState", "debtTypes", "hasActiveBudgetState"]),
        icon() {
            if (this.debt.type === "credit_card") {
                return "creditCard";
            } else if (this.debt.type === "loan") {
                return "bankNote";
            }
            return "unknown";
        },
        state() {
            if (this.budgetState.activeDebt === this.debt.id) {
                return "border bg-yellow-100 border-yellow-500";
            }
            return "bg-white hover:bg-gray-100";
        },
        debtType() {
            return this.debtTypes.find(d => d.key === this.debt.type);
        },
        debtState() {
            // if (this.budgetState.activeEntry === this.entry.id) {
            //     return 'border bg-yellow-100 border-yellow-500'
            // } else {
            //     if (this.entry.status === 'cleared') {
            //         return 'bg-green-50 hover:bg-green-100'
            //     } else if (this.entry.status === 'paid') {
            //         return 'bg-yellow-50 hover:bg-yellow-100'
            //     } else if (this.entry.status === 'goal') {
            //         return 'bg-red-50 hover:bg-red-100'
            //     } else {
            //         return 'bg-white hover:bg-gray-100'
            //     }
            // }
        }
    },
    methods: {
        ...mapActions(["setBudgetState"]),
        details() {
            if (this.budgetState.right === "modify-debt") {
                return;
            } else if (
                this.budgetState.activeDebt === this.debt.id &&
                this.budgetState.right === "details-debt"
            ) {
                this.setBudgetState({ left: true, right: "stats-debts" });
            } else {
                this.setBudgetState({
                    left: true,
                    right: "details-debt",
                    activeDebt: this.debt.id,
                    data: this.debt
                });
            }
        }
    }
};
</script>
