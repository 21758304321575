<template>
    <f-input :label="label"
             placeholder="0.00"
             right
             :value="value"
             left-add-on="$"
             :error="error"
             @input="update"
             @blur="blur">
    </f-input>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: 'Amount'
        },
        value: {
            type: [String, Number]
        },
        error: {
            type: String
        }
    },
    methods: {
        update(value) {
            this.$emit('input', value)
        },
        blur(value) {
            this.$emit('blur', value)
        }
    }
}
</script>
