<template>
    <div>
        <dt class="text-sm font-medium text-gray-500">Payment History</dt>
        <dd class="mt-1 text-sm text-gray-900">
            <ul role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">
                <template v-if="payments.length >= 1">
                    <li v-for="payment in payments" class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                        <div class="flex w-0 flex-1 items-center">
                            <icon :name="icon" stroke size="6" class="entry-handle mr-2 cursor-move text-gray-300 hover:text-gray-700"></icon>
                            {{ actionDate(payment) }}
                        </div>
                        <div class="ml-4 flex-shrink-0">
                            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
                                {{ payment.amount | currency }}
                            </a>
                        </div>
                    </li>
                </template>
            </ul>
        </dd>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    props: {
        debt: {
            type: Object
        }
    },
    computed: {
        icon() {
            if (this.debt.type === 'credit_card') {
                return 'creditCard'
            } else if (this.debt.type === 'loan') {
                return 'bankNote'
            }
            return 'unknown'
        },
        payments() {
            console.log('payments', this.debt.actions.filter(action => action.actionType === 'payment'));
            return this.debt.actions.filter(action => action.actionType === 'payment');
        }
    },
    methods: {
        actionDate(payment) {
            return moment(payment.actionDate).format('MM/DD/YYYY')
        }
    }
}
</script>
