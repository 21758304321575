<template>
    <div>
        <template v-for="(income, index) in incomes">
            <income :key="`income-${index}`" :income="income" />
            <budget-to-date-stats v-if="showToDateStats(income)" />
        </template>
        <income v-if="hasUnassigned" :income="unassignedIncome" />
    </div>
</template>

<script>
import Income from "@/views/dashboard/budget/Income";
import { mapGetters } from "vuex";
import moment from "moment";
import BudgetToDateStats from "@/components/budget/BudgetToDateStats";

export default {
    components: {
        Income,
        BudgetToDateStats
    },
    data() {
        return {
            outStanding: 0
        };
    },
    computed: {
        ...mapGetters([
            "incomes",
            "unassigned",
            "budgetMonth",
            "budgetToDateStats"
        ]),
        hasUnassigned() {
            return this.unassigned.income.length >= 1;
        },
        unassignedIncome() {
            return {
                entries: this.unassigned.income
            };
        }
    },

    methods: {
        showToDateStats(income) {
            return (
                moment().format("YYYY-MM-01") === this.budgetMonth &&
                this.budgetToDateStats.id === income.id
            );
        }
    }
};
</script>
