var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('budget-header',{staticClass:"mb-4"}),_vm._v(" "),_c('div',{staticClass:"px-4 pb-4"},[(!_vm.budget && !_vm.isLoading)?_c('div',[_c('budget-create')],1):_c('budget-divided',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',{staticClass:"h-full",class:{ 'hidden md:inline': !_vm.budgetState.left }},[_c('div',{staticClass:"h-full"},[_c('budget-right-header',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('ub-button',{attrs:{"size":"sm","icon":"currencyDollar","variant":_vm.budgetState.left === 'incomes'
                                            ? 'primary'
                                            : 'secondary',"outline":""},on:{"click":_vm.viewIncomes}}),_vm._v(" "),_c('ub-button',{attrs:{"size":"sm","icon":"viewBoard","variant":_vm.budgetState.left === 'groups'
                                            ? 'success'
                                            : 'secondary',"outline":""},on:{"click":_vm.viewGroups}}),_vm._v(" "),_c('ub-button',{attrs:{"size":"sm","icon":"creditCard","variant":_vm.budgetState.left === 'debts'
                                            ? 'success'
                                            : 'secondary',"outline":""},on:{"click":_vm.viewDebts}})]},proxy:true},{key:"right",fn:function(){return [(_vm.budgetState.left === 'incomes')?_c('ub-button',{attrs:{"size":"sm","icon":"plus","variant":"secondary","outline":""},on:{"click":_vm.createIncome}}):_vm._e(),_vm._v(" "),(_vm.budgetState.left === 'groups')?_c('ub-button',{attrs:{"size":"sm","icon":"plus","variant":"secondary","outline":""},on:{"click":_vm.createGroup}}):_vm._e(),_vm._v(" "),_c('ub-button',{attrs:{"size":"sm","icon":"refresh","variant":"secondary","outline":""},on:{"click":_vm.loadBudget}})]},proxy:true}])}),_vm._v(" "),(
                                _vm.budgetState.left === 'incomes' ||
                                    !_vm.budgetState.left
                            )?_c('incomes'):_vm._e(),_vm._v(" "),(_vm.budgetState.left === 'groups')?_c('groups'):_vm._e(),_vm._v(" "),(_vm.budgetState.left === 'debts')?_c('debts'):_vm._e()],1)])]},proxy:true},{key:"right",fn:function(){return [_c('div',{staticClass:"h-full",class:{ 'hidden md:inline': !!_vm.budgetState.left }},[(!_vm.budgetState.right)?_c('budget-stats'):_vm._e(),_vm._v(" "),(_vm.budgetState.right === 'stats-debts')?_c('debt-stats'):_vm._e(),_vm._v(" "),(_vm.budgetState.right === 'modify-income')?_c('income-form',{staticClass:"object-top",attrs:{"income":_vm.budgetState.data}}):_vm._e(),_vm._v(" "),(_vm.budgetState.right === 'modify-group')?_c('group-form',{staticClass:"object-top",attrs:{"group":_vm.budgetState.data}}):_vm._e(),_vm._v(" "),(_vm.budgetState.right === 'modify-entry')?_c('entry-form',{staticClass:"object-top",attrs:{"entry":_vm.budgetState.data}}):_vm._e(),_vm._v(" "),(_vm.budgetState.right === 'details-debt')?_c('debt-details',{staticClass:"object-top",attrs:{"debt":_vm.budgetState.data}}):_vm._e(),_vm._v(" "),(_vm.budgetState.right === 'modify-debt')?_c('debt-form',{staticClass:"object-top",attrs:{"debt":_vm.budgetState.data}}):_vm._e()],1)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }